import React, { useState, useEffect } from "react";
import {  BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import "./PartNumberList.css";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditPartNumber from "../PopUpsFolder/EditPartNumber";
import Loading from "../Common/Loading/Loading";
import NameProfile from "../Common/CommonJsx/NameProfile";
import TicketTitlePopUp from "../ListsPages/TitlePopUp";
import CreateAsset from "../PopUpsFolder/CreateAsset";
import { debounce } from "../../constants/helper";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";
import Pagination from "../Common/CommonJsx/Pagination";

function AssetsNumberList() {
    const [searchTerm, setSearchTerm] = useState('');
    const [createPartBOM, setCreatePartBOM] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [partLengthOfList, setPartLengthOfList] = useState(0);
    const itemsPerPage = 10;
    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [resetButton, setResetButton] = useState(false);
    const [loading, setLoading] = useState(false)
    const [partNumbers, setPartNumbers] = useState([]);
    const [editPartPopUp, setEditPartPopUp] = useState(false);
    const [rightSectionItems, setRightSectionItems] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [partAction, setPartAction] = useState('')
    const [varientFrom, setVarientFrom] = useState('')
    const [attributeOptions, setAttributeOptions] = useState({});
    
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
  
    // const [partName, setPartName] = useState("");
  
    const [id, setId] = useState("");
  
    const nav = useNavigate();
  
    const openCreatePartPopUp = () => {
      if (attributeOptions !== null) {
        getActiveFields();
        setCreatePartBOM(!createPartBOM);
      } else {
        nav('/part-number')
      }
  
      // getAttributes()
    };
  
    const openCreatePartBOM = async () => {
      // getActiveFields();
      setCreatePartBOM(!createPartBOM);
      // getAttributes()
    };
  
  
  
    
    const getAssetNumberList = async (sortField, sortOrder) => {
      try {
        setLoading(true);
        const headers = {
          "x-auth-token": Cookies.get("token"),
        };
        const listofPartNumber = await axios.get(
          `${BASE_URL}/v1/asset/get-asset-list`,
          {
            params: {
              page: currentPage,
              limit: itemsPerPage,
              
              search: searchTerm,
              
            },
            headers: headers,
          }
        );
  
        setPartNumbers(listofPartNumber.data.data.asset_list);
       
        setPartLengthOfList(listofPartNumber.data.data.pagination.total);
        setTotalPages(listofPartNumber.data.data.pagination.total_pages);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    const handleSort = (field) => {
      const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
      setSortField(field);
      setSortOrder(order);
      getAssetNumberList(field, order);
    };
  
  
    useEffect(() => {
      if (searchTerm !== '' || selectedStatus) {
      
        debounce(getAssetNumberList)()
      }
      else {
        getAssetNumberList();
      }
  
    }, [currentPage, itemsPerPage, partAction, searchTerm, selectedStatus]);
  
  
  
  
    useEffect(() => {
      getAttributes();
    }, []);
  
    const getAttributes = async () => {
      try {
        const headers = {
          "x-auth-token": Cookies.get("token"),
        };
        const fields = await axios.get(
          `${BASE_URL}/v1/asset/get-attributes`,
          { headers }
        );
        setAttributeOptions(fields.data.data.attribute_options);
      } catch (error) {
        console.log(error);
      }
    }
  
    const openeditPartPopUp = (e, id, partnumber, action) => {
      console.log(partnumber, action)
      setVarientFrom(partnumber)
      setId(id)
      e.stopPropagation();
      getActiveFields();
      setEditPartPopUp(true);
      // getAttributes()
    };
    const closeOpenPopUpEdit = () => {
      setEditPartPopUp(false);
    };
    function incrementValue(item) {
      const lastChar = item.default_value.slice(-1);
      let result;
  
      if (!isNaN(parseInt(lastChar, 10))) {
        result = parseInt(item.default_value, 10) + (partLengthOfList + 1);
      } else {
        const nextLetter = String.fromCharCode(lastChar.charCodeAt(0) + (partLengthOfList + 1));
        result = item.default_value.slice(0, -1) + nextLetter;
      }
  
      return result;
    }
    const getActiveFields = async () => {
      try {
        const headers = {
          "x-auth-token": Cookies.get("token"),
        };
        const fields = await axios.get(
          `${BASE_URL}/v1/asset/get-active-asset-name`,
          { headers, params: { page: currentPage, limit: itemsPerPage } }
        );
  
        
  
        fields.data.data.active_asset_format.forEach(item => {
          if (item.is_unique && item.variable_selected_type === 'Counter') {
            if (item.hasOwnProperty('default_value')) { // Check if ctr property exists
              item.default_value = incrementValue(item);
            }
          }
        });
        setRightSectionItems(fields.data.data.active_asset_format);
        console.log(fields.data.data.active_asset_format)
        // setPartName(fields.data.data.nomenclature.partNameNomenclature);
        //   generateDynamicPartName()
        // console.log(fields.data.data.active_asset_format)
      } catch (error) {
        console.log(error);
      }
    };
  
    const partView = (id) => {
  
      nav(`/asset-view/${id}`);
    };
  
    
    // const itemsPerPage = 10;
  
  
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePageChange = (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
    const handleTitleClick = (event, department) => {
      event.stopPropagation();
      setTicketPopUp(true);
      setTicketTitle(department);
    };
    const HandleTitleClose = () => {
      setTicketPopUp(false);
    };
    const statusOptions = ['Draft', 'Pending Approval', 'Released']
    // const uniqueOptions = statusOptions.map((option) => ({
    //   value: option,
    //   label: option,
    // }));
  
    // setAssignedOptions(statusOptions);
  
    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: "30px",
        width: "200px",
        border: "1px solid #EDF2F7",
        padding: "0px 15px",
        boxSizing: "border-box",
        height: "40px",
      }),
    };
  
  
    const handleNavigate = (e, link,id) => {
      e.stopPropagation();
      if (!link) {
        nav(`/part-view/${id}`)
        // If link is empty or undefined, do nothing
        return;
      }
      const url = link.includes('EC')
        ? `/ec-ticket-view/${link}`
        : `/deviation-ticket-view/${link}`;
      
      window.open(url, '_blank');
    };
    return (
      <>
  
        <div className="part-number-list-container">
          <div className="part-number-list-title">
            <span>Assets</span>
            <div className="part-number-list-btns">
              {/* <button onClick={openCreatePartBOM}>
                  <AddOutlinedIcon />
                  Create part BOM
                </button> */}
              <button onClick={openCreatePartPopUp}>
                <AddOutlinedIcon />
                Create new asset
              </button>
            </div>
          </div>
          {/* <div className="part-number-list-filters">
            <div className="part-number-list-search">
              <SearchIcon />
              <input type="text" placeholder="Search" value={searchTerm}
                onChange={handleSearchChange} />
            </div>
            <div className="part-number-list-spn">
              <span
                className={partAction === '' ? 'active' : ''}
                onClick={() => handleFilter('')}
              >
                All
              </span>
              <span
                className={partAction === 'creation' ? 'active' : ''}
                onClick={() => handleFilter('creation')}
              >
                Root part
              </span>
              <span
                className={partAction === 'variation' ? 'active' : ''}
                onClick={() => handleFilter('variation')}
              >
                Part variations
              </span>
              <span
                className={partAction === 'bom' ? 'active' : ''}
                onClick={() => handleFilter('bom')}
              >
                Part BOM
              </span>
            </div>
            <Select
              styles={customStyles}
              placeholder="Status"
              options={statusOptions.map((option) => ({
                value: option,
                label: option,
              }))}
              value={selectedStatus ? { value: selectedStatus, label: selectedStatus } : null}
              onChange={handleStatusChange} // Set the onChange event handler
            />
            {resetButton && <button
              className="reset-btn"
              onClick={handleReset}
              data-tour="step-11"
            >
              reset <TuneOutlinedIcon />
            </button>}
  
          </div> */}
          <div>
  
  
            <table className="part-number-list-table-container">
              <thead>
                <tr>
                  <th className="part-number-list-th1" onClick={() => handleSort('part_number')} width='175px'>Asset number 
                 </th>
                  <th className="part-number-list-th1" onClick={() => handleSort('asset_title')}  width='260px'>Asset title</th>
                 
                  <th onClick={() => handleSort('created_by.fullName')} width='175px'>Created by</th>
                  <th onClick={() => handleSort('createdAt')} width='175px'>Created on</th>
                  {/* ec_dev_reference */}
                 
                </tr>
              </thead>
              {loading ? <Loading /> :
                <tbody>
                  {partNumbers.map((num, index) => {
                   
                    return (
                      <tr onClick={() => partView(num.asset_number)} key={index}>
                        <td>{num.asset_number}</td>
                        <td>
  
                          {/* {department.data.defaultFieldsData.ticket_title.value} */}
                          {num.asset_title && num.asset_title.length > 30 ? (
                            <>
                              {num.asset_title.slice(
                                0,
                                30
                              )}
                              ...
                              <button
                                className="view-more-text"
                                onClick={(event) =>
                                  handleTitleClick(event, num.asset_title)
                                }
                              >
                                view
                              </button>
                            </>
                          ) : (
                            num.asset_title
                          )}</td>
                       
                        <td className="part-number-photo">
                          <NameProfile userName={num.created_by.fullName} width='26px' memberPhoto={num.created_by.photo} fontweight='500' />
                          <span>{num.created_by.fullName}</span>
                        </td>
                        <td>
                        <DateFormateForList formateTime={num.createdAt}/>
                        </td>
                       
                       
                      </tr>
                    );
                  })}
  
  
                </tbody>
              }
            </table>
  
          </div>
          {totalPages > 1 && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
          )}
        </div>
  
  
        {editPartPopUp && (
          <EditPartNumber
            handleClose={closeOpenPopUpEdit}
            handleopen={openeditPartPopUp}
            setRightSectionItems={setRightSectionItems}
            setAttributeOptions={setAttributeOptions}
            rightSectionItems={rightSectionItems}
            editPartPopUp={editPartPopUp}
            varientFrom={varientFrom}
            getAssetNumberList={getAssetNumberList}
            id={id}
            attributeOptions={attributeOptions}
          />
        )}
        {/* {createPartPopUp && (
          <CreatePartNumber
            handleClose={openCreatePartPopUp}
            setRightSectionItems={setRightSectionItems}
            rightSectionItems={rightSectionItems}
            editPartPopUp={editPartPopUp}
            getAssetNumberList={getAssetNumberList}
            attributeOptions={attributeOptions}
          />
        )} */}
        {createPartBOM && (
          <CreateAsset
            handleClose={openCreatePartBOM}
            setRightSectionItems={setRightSectionItems}
            rightSectionItems={rightSectionItems}
            editPartPopUp={editPartPopUp}
            getAssetNumberList={getAssetNumberList}
            attributeOptions={attributeOptions}
            partLengthOfList={partLengthOfList}
            setAttributeOptions={setAttributeOptions}
          // partNumberOptions={partNumberOptions}
          />
        )}
        {(ticketPopUp) && (
          <TicketTitlePopUp
            onClose={HandleTitleClose}
            ticketTitle={ticketTitle}
          />
        )}
      </>
    );
  }

export default AssetsNumberList