// debounce.js
export const debounce = (func, delay = 500) => {
  let debounceTimer;
  
  return function(...args) {
    const context = this;

    // Clear the timer if it's already running
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new timer
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};


  

 export const debounceScroll = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};


export const makeByCodeOptions = [
  { value: 'in_house_production', label: 'In-house production' },
  { value: 'subcontract', label: 'Subcontract' },
  { value: 'purchased', label: 'Purchased' },
  { value: 'phantom_kit', label: 'Phantom / Kit' },
  { value: 'software_firmware', label: 'Software / Firmware' },
];