import React, { useEffect, useState } from 'react';
import './ProductionSchedule.css';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateProductionPlanning from './CreateProductionPlanning';
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import Loading from './../Common/Loading/Loading';
import Pagination from "../Common/CommonJsx/Pagination";
import { toast } from 'react-toastify';

function ProductionPlanning() {
    const [createPlan, setCreatePlan] = useState(false)
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [planningData, setPlanningData] = useState([])

    const handleEdit = async (id) => {
        // Handle edit functionality
        try {
            const headers = {
                'x-auth-token': Cookies.get("token")
            };
            const payload = {
                id
            };
            console.log(payload)
            const response = await axios.post(`${BASE_URL}/v1/prod-plan/apply-work-order`, payload, { headers });
            console.log(response)
            if(response.data.meta.success === false){
                toast.error(response.data.meta.message)
            }else{
                await allWorkPlan()
            }
          
            // window.location.reload()



        } catch (error) {
            console.error("Error saving MBOM:", error);

        }

    };

    const handleOpenCreateWorkorder=()=>{
        setCreatePlan(!createPlan)
    }
    useEffect(() => {
        allWorkPlan()
    }, [currentPage, itemsPerPage])
    const allWorkPlan = async () => {

        try {
            setLoading(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/prod-plan/get-all-prod-plans`,

                {
                    params: {
                        page: currentPage,
                        limit: itemsPerPage,
                        // search: searchTerm,

                    },
                    headers: headers,
                }
            );
            console.log(response)
            setPlanningData(response.data.data.productionPlans)


            setTotalPages(response.data.data.totalPages);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    return (
        <>
            <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start',marginTop:'80px',padding:'24px'}} >
         <div className='department-table' >
                <div className='prod-sche-top-nav'>
                    <div className='prod-schedule-back-nav'>

                        <span>Work order</span>
                    </div>
                    <div className='prod-schedule-filters'>
                        <button className="tkt-btn" onClick={handleOpenCreateWorkorder}>
                            <AddOutlinedIcon /> New work order
                        </button>
                    </div>
                </div>
                {loading ? <Loading /> :
                 <div style={{ width: '100%', padding: '0px' }}>
                    <table className="department-table-data">
                        <thead>
                            <tr>
                                <th className="id-header">Model</th>
                                <th className="tck-header">Start date</th>
                                <th className="tck-header">End date</th>
                                <th className="tck-header">Quantity</th>
                                <th className="tck-header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {planningData.map(item => (
                                <tr
                                    key={item.id}
                                >
                                    <td>{item.product_name}</td>
                                    <td>{item.start_date}</td>
                                    <td>{item.end_date}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                        {item.applied_to_schedule ? 'Applied' : 'Not applied'}

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                
            </div>
           {totalPages > 1 && (
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        )}
        </div>
        {createPlan && <CreateProductionPlanning onclose={()=>setCreatePlan(!createPlan)}/>}
        </>
    

           
           

    );
}

export default ProductionPlanning;
