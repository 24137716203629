import React from 'react'

function DateFormateForList({formateTime}) {
    const formatCreatedAtDate = (createdAt) => {
        // Attempt to create a Date object from ISO 8601 format
        const isoDateObject = new Date(createdAt);
    
        // If the ISO date is valid, format it
        if (!isNaN(isoDateObject.getTime())) {
            const formattedDate = new Intl.DateTimeFormat('en-US', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            }).format(isoDateObject);
    
            return formattedDate;
        }
    
        // Fallback: Custom date parsing
        try {
            const [datePart, timePart] = createdAt.split(', ');
            const [day, month, year] = datePart.split('/');
            const [time, period] = timePart.split(' ');
    
            // Convert month to zero-based index
            const monthIndex = parseInt(month, 10) - 1;
    
            // Adjust hour based on period (AM/PM)
            let [hour, minute, second] = time.split(':').map(Number);
            if (period.toLowerCase() === 'pm' && hour !== 12) {
                hour += 12;
            } else if (period.toLowerCase() === 'am' && hour === 12) {
                hour = 0;
            }
    
            // Create a Date object from the custom format
            const dateObject = new Date(year, monthIndex, day, hour, minute, second);
    
            // Check if the date is valid
            if (isNaN(dateObject.getTime())) {
                throw new Error('Invalid Date');
            }
    
            // Format the date
            const formattedDate = new Intl.DateTimeFormat('en-US', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            }).format(dateObject);
    
            return formattedDate;
        } catch (error) {
            console.error('Invalid Date:', error);
            return 'Invalid Date';
        }
    };
  return (
    <>
    {formatCreatedAtDate(formateTime)}
    </>
  )
}

export default DateFormateForList