import React, { useState, useEffect } from "react";
import "./DepartmentForm.css";
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";

import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  PHOTO_LINK,
  TICKET_ATTACHMENT_BUCKET,
  ASSET_PREFIX_URL,
  BASE_URL,
  
} from "./../../constants/config";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import NameProfile from "../Common/CommonJsx/NameProfile";
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";

// const formatDate = (dateString) => {
//   if (!dateString) return ""; // Handle empty or undefined dates
//   const date = new Date(dateString);
//   const day = date.getDate();
//   const month = date.toLocaleString("default", { month: "short" });
//   const year = date.getFullYear();
//   return `${day}-${month}-${year}`;
// };

function DepartmentForm({ onClose, departmentId,fromDepartment }) {
  const [formData, setFormData] = useState({});
  
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [fileFormat, setFileFormat] = useState('');
  
  const [selectMember, setSelectMember] = useState([])
  const [deptName, setDeptName] = useState('')
  const [label, setLabel] = useState([]);
  const [type, setType] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [templateId, setTemplateId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileSizeError, setFileSizeError] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [owner, setOwner] = useState({});

  const nav = useNavigate();


  useEffect(() => {
    fetchTicketDetails();
  }, []);

  const { department } = useParams();
  const fetchTicketDetails = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(
        `${BASE_URL}/v1/depart/get-tempalteId/${departmentId}`,
        { headers: headers }
      );
      if (!response) {
        // Display an alert and close the component
        // alert('No fields found. Unable to create ticket.');
        onClose(); // Assuming onClose is a function to close the component
        return;
      }
      // console.log(response.data.data.fields.length)
      setOwner(response.data.data.profile_details);
      handleFieldChange("Owner", response.data.data.profile_details._id);
      setTemplateId(response.data.data.templateId);
      setDeptName(response.data.data.departmentNames);

      const filteredFields = response.data.data.fields.filter(
        (field) =>
          field.is_default_template === true ||
          field.is_default_template === undefined
      );
      //  const combinedArray = [...fieldsWithUndefinedOrTrue,...customFields]
      //  console.log(combinedArray)
      setLabel(filteredFields);
      // console.log(response.data.data.fields)
      setType(response.data.data.fields.map((item) => item.fieldName));
    } catch (error) {
      toast.info(
        `please create a template to create a ticket for ${department}`
      );
      onClose();
      console.error("Error fetching options data:", error);
    }
  };
  const getFileFormat = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toUpperCase();
    }
    return "Unknown";
  };

  let inputRef = React.createRef();

  const handleFieldChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleFileDrop = async (event) => {
    event.preventDefault();
   
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      handleFile(file);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleFile = async (file) => {
    setFileSizeError("");
    setUploadError("");
    const fileSizeMB = file.size / (1024 * 1024); // size in MB
    if (fileSizeMB > 2) {
      setFileSizeError("File size cannot be more than 2 MB"); // Notify the user
      return; // Exit the function
    }
    //fetch pre signed URL
    setIsLoading(true);
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const preSignedURL = await axios.post(
        `${BASE_URL}/v1/member/get-presigned-url`,
        { bucket_name: TICKET_ATTACHMENT_BUCKET, file: file.name },
        { headers: headers }
      );
      if (
        preSignedURL.data.meta.code === 200 &&
        preSignedURL.data.meta.message === "SUCCESS" &&
        preSignedURL.data.data.url
      ) {
        //upload to s3
         await axios.put(preSignedURL.data.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        // file.name = preSignedURL.data.data.file_name;
        handleFieldChange("file", file.name);
        setFileFormat(getFileFormat(file.name));
        setUploadedFiles((prevUploadedFiles) => [
          ...prevUploadedFiles,
          { name: file.name, key: preSignedURL.data.data.key },
        ]);
      } else {
        alert("Error generating signed URL");
      }
    } catch (e) {
      setUploadError("Error in uploading file");
    } finally {
      // Set isLoading back to false after upload (whether success or failure)
      setIsLoading(false);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
   
  };

 
  

  const handleSubmit = (event) => {
    event.preventDefault();

    Object.entries(formData).map(([type, value]) => ({
      type,
      value,
    }));
  };

  const handleCreate = async (event) => {
    event.preventDefault();

    const requiredFields = label.filter(
      (field) =>
        (field.is_mandatory || field.is_default) &&
        field.inputType !== "comments" &&
        field.fieldName !== "Owner"
    );
    const errors = {};

    requiredFields.forEach((field) => {
      if (field.inputType !== "attachment" && !formData[field.fieldName]) {
        errors[field.fieldName] = "This field is required.";
      }
    });
    // Check for attachment field and uploaded files
    // const attachmentField = label.find(field => field.inputType === 'attachment');
    // if (attachmentField && uploadedFiles.length === 0) {
    //   errors.Attachment = 'Please attach a file.';
    // }

    setFieldErrors(errors);

    if (Object.keys(errors).length === 0) {
      // Proceed with ticket creation if there are no errors
      const ticketData = {};
      const defaultFieldsData = {};

      label.forEach((field, index) => {
        let fieldName = field.fieldName.replace(/\s+/g, "_").toLowerCase(); // Replace spaces with underscores

        if (field.inputType === "attachment") {
          defaultFieldsData[fieldName.toString()] = {
            value: uploadedFiles.map((file) => {
              return { name: file.name, key: file.key };
            }),
            is_default: field.is_default,
            inputType: field.inputType,
            fieldName: field.fieldName,
            order: index + 1,
          };
        } else if (field.inputType === "person") {
          let selectedPerson;
          if (field.fieldName === "Owner") {
            console.log(owner.photo)
            selectedPerson = [
              {
                photo: owner.photo,
                _id: owner.id,
                fullName: owner.name,
              },
            ];
          } else if (field.fieldName === "Watchers") {
            let selectedMember = formData[field.fieldName]; // Assuming you store Person's full name in formData
            
            if (Array.isArray(selectedMember)) {
                selectedMember = selectedMember.map((item) => item.value);
            } else {
                selectedMember = []; // Handle the case where selectedMember is not an array
            }
        
            // Filter the selectMember array based on the selectedMember array
            selectedPerson = selectMember.filter((member) =>
                selectedMember.includes(member._id)
            );
        
            // If no watchers are selected, include an empty array in the formData
            formData[field.fieldName] = selectedMember;
        }
        
         else {
            let selectedMember = formData[field.fieldName]; // Assuming you store Person's full name in formData
            selectedPerson = selectMember.filter(
              (member) => member._id === selectedMember
            );
            // if(field.fieldName === 'Assign to')
            // console.log('selectedPerson',selectedPerson);
          }
          if (selectedPerson && selectedPerson[0]) {
            let value;
            if (field.fieldName === "Watchers") {
              value = selectedPerson.map((watcher) => ({
                photo: watcher.photo,
                member_id: watcher._id,
                fullName: watcher.fullName,
              }));
            } else {
              value = {
                photo: selectedPerson[0].photo,
                member_id: selectedPerson[0]._id,
                fullName: selectedPerson[0].fullName,
            };
          }
            if (field.is_default) {
              defaultFieldsData[fieldName.toString()] = {
                value,
                is_default: true,
                inputType: field.inputType,
                fieldName: field.fieldName,
                order: index + 1,
              };
            } else {
              ticketData[fieldName.toString()] = {
                value,
                is_default: false,
                inputType: field.inputType,
                fieldName: field.fieldName,
                order: index + 1,
              };
            }
          }
        } else if (field) {
          const fieldData = {
            value: formData[field.fieldName],
            is_default: field.is_default,
            inputType: field.inputType,
            fieldName: field.fieldName,
            option: field.options,
            order: index + 1,
          };
          if (field.is_default) {
            defaultFieldsData[fieldName.toString()] = fieldData;
          } else {
            ticketData[fieldName.toString()] = fieldData;
          }
        } else {
          ticketData[fieldName.toString()] = formData[field.fieldName];
        }
      });

      // Convert to JSON string
      const hasTicketData = Object.keys(ticketData).length > 0;
      const jsonData = {
        defaultFieldsData: { ...defaultFieldsData },
        customFieldsData: hasTicketData ? ticketData : {},
      };

      // You can now send the jsonData to your API or perform other actions
      // Example API call using axios
      try {
        const headers = {
          "x-auth-token": Cookies.get("token"),
        };

        const response = await axios.post(
          `${BASE_URL}/v1/ticket/create-ticket`,
          {
            data: jsonData,
            department_id: departmentId,
            departmentName: deptName,
            templateId,
          },
          { headers: headers }
        );

        // console.log(notify)
        nav(`/ticket-view/${response.data.data.departmentTicket.sequence_id}`);

        // window.location.reload()
      } catch (error) {
        console.error("Error fetching options data:", error);
      }
      console.log(jsonData)
      // nav(`/department-ticket/${deptName}/${departmentId}`);
    }
  };

  const handleSingleSelectChange = (fieldName, value) => {
    handleFieldChange(fieldName, value);
  };
  const handlePersonSelectChange = (fieldName, value) => {
    console.log(value)
    handleFieldChange(fieldName, value);
  };

  // const handleWatchers = (fieldName, value) => {
  //   const selectedValues = value.map(option => option.value);
  //   handleFieldChange(fieldName, selectedValues);
  // };

  const handleMultiSelectChange = (fieldName, selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    handleFieldChange(fieldName, selectedValues);
  };

  // const Priority = [
  //   { value: "P0", label: "P0", color: "#DA2370" },
  //   { value: "P1", label: "P1", color: "#EE962F" },
  //   { value: "P2", label: "P2", color: "#00B884" },
  //   { value: "P3", label: "P3", color: "#5696F2" },
  //   { value: "P4", label: "P4", color: "#5696F2" },
  //   // Add more options similarly
  // ];
 

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/setting/get-mem", {
        headers: headers,
      });
      setSelectMember(response.data.data.memberList);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  // /department/ticket-step1
 
  return (
    <div className="form-page">
      <div className="dept-ticket-home">
        <div className="dept-ticket-top">
          <div className="dept-ticket-head">
            <span>Create new ticket</span>
            <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
          </div>
         {fromDepartment ? '':<div className="dept-ticket-steps">
            <span>step 2 of 2</span>
          </div>} 
         {fromDepartment ? '' : <div>
            <img
              src={`${ASSET_PREFIX_URL}ticket-step2.svg`}
              alt=""
              width="100%"
            />
          </div>} 
        </div>
        <div onSubmit={handleSubmit} className="form-grid">
          {label.map((field, index) => (
            <div
              key={field}
              className={
                field.inputType === "attachment"
                  ? "full-width-item"
                  : "grid-item"
              }
              style={
                field.inputType === "comments" ? { display: "none" } : null
              }
            >
               {field.inputType === 'attachment' && <span>{field.fieldName}</span>}
              {field.inputType !== 'attachment' &&<span>{field.fieldName}{field.is_mandatory===true?'*':''}</span>}
              {/* <span>{field.fieldName}*:</span> */}
              {field.fieldName === "Owner" ? (
                <div>
                  <Select
                    placeholder={field.placeholder}
                    isDisabled={true}
                    value={{
                      label: (
                        <div
                          className="member-option"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                        
                           <NameProfile userName={owner.name} width='26px' memberPhoto={owner.photo}  fontweight='500' />
                           
                         

                          {owner.name}
                          <span className="you-text">Me</span>
                        </div>
                      ),
                    }}
                  />
                  {/* Render any error messages for the Owner field */}
                </div>
              ) : field.inputType === "person" ? (
                <div>
                  <Select
                    placeholder={field.placeholder}
                    isMulti={field.fieldName === "Watchers" ? true : false}
                    options={selectMember.map((member) => ({
                      value: member._id, // Use full name as the value
                      label: (
                        <div
                          className="member-option"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          
                           <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo}  fontweight='500' />
                           
                          
                          {member.fullName}
                        </div>
                      ),
                    }))}
                    onChange={(selectedOption) => {
                      field.fieldName === "Watchers"
                        ? handlePersonSelectChange(
                            field.fieldName,
                            selectedOption
                          )
                        : handlePersonSelectChange(
                            field.fieldName,
                            selectedOption.value
                          );
                    }}
                    value={
                      formData[field.fieldName]
                        ? formData[field.fieldName]._id
                        : null
                    } // Use fullName as value
                  />
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                  {/* Render any error messages for the Owner field */}
                </div>
              ) : field.inputType === "text" ||
               
                field.inputType === "team" ? (
                <>
                  <div className="type-texts">
                    <input
                      type="text"
                      placeholder={field.placeholder}
                      value={formData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleFieldChange(field.fieldName, e.target.value)
                      }
                      disabled={
                        field.inputType === "date" &&
                        field.label === "CreatedOn"
                      }
                    />
                  </div>
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ) : field.inputType === "paragraph" ?(
                <>
                  <div className="type-texts">
                    <textarea
                      type="text"
                      placeholder={field.placeholder}
                      value={formData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleFieldChange(field.fieldName, e.target.value)
                      }
                      disabled={
                        field.inputType === "date" &&
                        field.label === "CreatedOn"
                      }
                    />
                  </div>
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ):field.inputType === "single_select" ? (
                <>
                  <Select
                    placeholder={field.placeholder}
                    options={field.options.map((opt) => ({
                      value: opt,
                      label: opt,
                    }))}
                    onChange={(selectedOption) =>
                      handleSingleSelectChange(
                        field.fieldName,
                        selectedOption.value
                      )
                    }
                    value={
                      formData[field.fieldName]
                        ? {
                            value: formData[field.fieldName],
                            label: formData[field.fieldName],
                          }
                        : null
                    }
                  />
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ) : field.inputType === "multi_select" ? (
                <>
                  <Select
                    placeholder={field.placeholder}
                    options={field.options.map((opt) => ({
                      value: opt,
                      label: opt,
                    }))}
                    isMulti
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(field.fieldName, selectedOptions)
                    }
                  />
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ) : field.inputType === "number" ? (
                <>
                  <div className="type-texts">
                    <input
                      type="number"
                      placeholder={field.placeholder}
                      value={formData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleFieldChange(field.fieldName, e.target.value)
                      }
                      disabled={
                        field.inputType === "date" &&
                        field.label === "CreatedOn"
                      }
                    />
                  </div>
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </> 
              ) : field.inputType === "date" ? (
                <>
                  <div className="type-texts">
                    <input
                      type="date"
                      placeholder={field.placeholder}
                      value={formData[field.fieldName] || ""}
                      // value={formData[field.fieldName] ? formatDate(formData[field.fieldName]) :  ''}
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        handleFieldChange(field.fieldName, e.target.value)
                      }
                    />
                  </div>
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ) : field.inputType === "date_range" ? (
                <>
                  <div className="type-texts">
                    <div>
                      <span>Start Date</span>
                      <input
                        placeholder={field.placeholder}
                        type="date"
                        value={formData[field.fieldName]?.start || ""}
                        min={new Date().toISOString().split("T")[0]}
                        onChange={(e) =>
                          handleFieldChange(field.fieldName, {
                            ...formData[field.fieldName],
                            start: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <span>End Date</span>
                      <input
                        type="date"
                        value={formData[field.fieldName]?.end || ""}
                        min={formData[field.fieldName]?.start}
                        onChange={(e) =>
                          handleFieldChange(field.fieldName, {
                            ...formData[field.fieldName],
                            end: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  {/* <RangePicker
                    value={dateRange}
                    onChange={handleDateChange}
                    style={{ width: 370 }}
                    allowClear={false}
                    disabledDate={disabledDate}
                  /> */}
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ) : field.inputType === "link" ? (
                <>
                  <div className="type-texts">
                    <input
                      type="url"
                      placeholder={field.placeholder}
                      value={formData[field.fieldName] || ""}
                      onChange={(e) =>
                        handleFieldChange(field.fieldName, e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              ) : field.inputType === "attachment" ? (
                <div>
                  <input
                    type="file"
                    onChange={(e) => handleFileInputChange(e)}
                    className="full-width-input"
                    ref={inputRef}
                    style={{ display: "none" }}
                  />

                  <div
                    className="filedropzone"
                    onDrop={handleFileDrop}
                    onDragOver={handleDragOver}
                    // onDragLeave={handleDragLeave}
                  >
                    <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                    {isLoading ? (
                      <p>Uploading ....</p>
                    ) : (
                      <p>
                        Drop files to attach or
                        <span onClick={() => inputRef.current.click()}>
                          {" "}
                          Browse
                        </span>
                      </p>
                    )}
                  </div>

                  {fieldErrors[field.fieldName] && (
                    <span className="error-message">
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                  <div className="file-info">
                    {fileFormat && <p>File Format: {fileFormat}</p>}
                    {/* {uploading && <div className="loading-line" />} */}
                  </div> 

                  
                  <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload'/>

                  {uploadError && <div className="error">{uploadError}</div>}
                  {fileSizeError && (
                    <div className="error">
                      <span
                        className="error-message"
                        style={{ color: "#FF9845" }}
                      >
                        <ErrorOutlineOutlinedIcon /> {fileSizeError}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="type-texts">
                    <input
                      type={type[index]}
                      value={formData[type[index]] || ""}
                      onChange={(e) =>
                        handleFieldChange(type[index], e.target.value)
                      }
                      disabled={type[index] === "date" && field === "CreatedOn"}
                    />
                  </div>

                  {fieldErrors[field.fieldName] && (
                    <span className="error-message" style={{ color: "red" }}>
                      <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                      {fieldErrors[field.fieldName]}
                    </span>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        <div className="depart-form-btns">
          <button className="dept-form-submit" onClick={handleCreate}>
            Create ticket
          </button>

          <button className="dept-form-cancel" onClick={onClose}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default DepartmentForm;
