import React, { useState, useEffect } from "react";
import "./DetailsView.css";
import "../DepartmentPages/DepartmentForm.css"
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  DEFAULT_PHOTO,
  PHOTO_LINK,
  TICKET_ATTACHMENT_BUCKET,
  ASSET_PREFIX_URL,
} from "../../constants/config";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ChangeLogs from "./ChangeLogs";
import ChangesPopUp from "./ChangesPopUp";
import DeleteTicketPopUp from "./DeleteTicketPopUp";
import Loading from "../Common/Loading/Loading";
import NameProfile from "../Common/CommonJsx/NameProfile";
import EcApproveList from "../PopUpsFolder/EcApproveList";
import RejectPopUp from '../PopUpsFolder/RejectPopUp';
import PartsBeforePopUp from "../PopUpsFolder/PartsBeforePopUp";
import ErrorPartsPopUp from "../PopUpsFolder/ErrorPartsPopUp";
import EcpartsPage from "./EcpartsPage";
import DevEcList from './devEcList';
import PartNumberCountFormat from "../Common/CommonJsx/PartNumberCountFormat";
import BomHierarchy from "../PartNumbers/BomHierarchy/Bomhierarchy";
import ExcelView from "../PartNumbers/BomHierarchy/ExcellView";
import { debounce } from "../../constants/helper";
import ApproversComponent from "./ApproversComponent";
import ApprovingStatusContainer from "./ApprovingStatusContainer";
import ApprovePopUp from "../PopUpsFolder/ApprovePopUp";




function DetailsViewPage({ toastStyle, type }) {

  const [fileFormat, setFileFormat] = useState("");
  const [partsCount, setPartsCount] = useState(0);
  const [approvalCount, setApprovalCount] = useState(null)
  const [ecsList, setEcsList] = useState([])
  const [seqId, setSeqId] = useState("");
  const [ticketName, setTicketName] = useState("");
  const [editTicketName, setEditTicketName] = useState("");
  const [notifyIds, setNotifyIds] = useState([]);
  const [ticketDescription, setTicketDescription] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [comentLabel, setCommentLabel] = useState("");
  const [memberPhoto, setMemberPhoto] = useState({});
  const [ticketId, setTicketId] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [priority, setPrority] = useState("");
  const [department, setDepartment] = useState("");
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({});
  const [defaultDetails, setDefaultDetails] = useState({});
  const [memberDetails, setMemberDetails] = useState([]);
  const [watchersDetails, setWatchersDetails] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showButtonsDescrip, setShowButtonsDescrip] = useState(false);
  const [showButtonsText, setShowButtonsText] = useState(false);
  const [comments, setComment] = useState("");
  const [editable, setEditable] = useState(false);
  const [editableComment, setEditableComment] = useState(false);
  const [shouldRevert, setShouldRevert] = useState(false);
  const [approvalMemberView, setApprovalMemberView] = useState(false);
  const [resultApprovalMemberView, setResultApprovalMemberView] = useState(false);
  const [ecApprovalStatus, setEcApprovalStatus] = useState('');
  const [memberApproved, setMemberApproved] = useState('');
  const [ecTitles, setEcTitles] = useState([])
  const [showComment, setShowComment] = useState([]);
  const [showEditButtons, setShowEditButtons] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [showEditSaveButtons, setShowEditSaveButtons] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [changeDetails, setChangeDetails] = useState(false);
  const [openDelete, setDeletepopup] = useState(false);
  const [newData, setNewData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [selectedInputKey, setSelectedInputKey] = useState(null);
  const [customEditing, setCustomEditing] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [key, setKey] = useState("");
  const [textEditable, setTextEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false)
  const [linkInput, setLinkInput] = useState({})
  const [responseData, setResponseData] = useState({});
  const [attachmentDelete, setAttachmentDetete] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState('');
  const [searchParts, setSearchParts] = useState('');
  const [openApprover, setOpenApprover] = useState(false);
  const [openApproveList, setOpenApproveList] = useState(false);
  const [openPartsAdded, setOpenPartsAdded] = useState(false);
  const [openErrorsParts, setOpenErrorsParts] = useState(false);
  const [searcingApprovals, setSearcingApprovals] = useState('');
  const [searchCondition, setSearchCondition] = useState(false);
  const [engineeringDetails, setEngineeringDetails] = useState({});
  const [approvalListOfMembers, setApprovalListOfMembers] = useState([]);
  const [errorParts, setErrorParts] = useState([]);
  const [partsToBeAdded, setPartsToBeAdded] = useState([]);
  const [editEngineeringDetails, setEditEngineeringDetails] = useState({
    engineering: '',
    production: '',
    service: '',
  });
  const [engineeringEditing, setEngineeringEditing] = useState({
    engineering: false,
    production: false,
    service: false,
  });
  let defaultState = 'details';
  let defaultViewState = 'excel';
  const [selectedSection, setSelectedSection] = useState(type !== 'pci-view' ? defaultState : 'approval');
  const [selectedApproverSection, setSelectedApproverSection] = useState('test-approval');
  const [selectedEcType, setSelectedEcType] = useState('');
  const [selectedViewType, setSelectedViewType] = useState('');



  const handleSectionClick = (section) => {
    setSearchParts('')
    setSearcingApprovals('')
    setSelectedSection(section);

  };
  const handleApprovalSectionClick = (section) => {

    setSelectedApproverSection(section)
    setSearchCondition(false)
    setSearcingApprovals('')

  };

  const handleSectionViewClick = (section) => {

    setSelectedViewType(section);

  };


  const handleFalseMaking = () => {
    setShowEditSaveButtons(false)
    setIsDropdownOpen(false)
    // setShowButtons(false)
  }

  const nav = useNavigate();
  // const handleBack = () => {
  //   nav(-1);
  // };
  const handleEditIconClick = (index) => {
    setEditingIndex(index);
    // setShowEditSaveButtons(true);

    const commentElement = document.getElementById(`comment-${index}`);
    commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };
  const { sequence_id } = useParams();

  useEffect(() => {
    fetchFormDetails();
  }, [sequence_id]);

  const fetchFormDetails = async () => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let response;
      if (type === 'template') {
        response = await axios.get(
          `${BASE_URL}/v1/ticket/get-ticketDetails/${sequence_id}`,
          { headers }
        );
      } else if (type === 'ec-template') {
        response = await axios.get(
          `${BASE_URL}/v1/ec-template/get-ec-view/${sequence_id}`,
          { headers }
        );
      } else if (type === 'ec-deviation') {
        response = await axios.get(
          `${BASE_URL}/v1/ec-deviations/get-ec-view/${sequence_id}`,
          { headers }
        );
      } else if (type === 'pc-template') {
        response = await axios.get(
          `${BASE_URL}/v1/prod-change/get-pc-view/${sequence_id}`,
          { headers }
        );
      } else if (type === 'pci-view') {
        response = await axios.get(
          `${BASE_URL}/v1/manufac-bom/get-pci-view/${sequence_id}`,
          { headers }
        );
      }
      if (type === 'ec-deviation' || type === 'ec-template' || type === 'pci-view') {
        setPartsCount(response.data.data.parts_count)
        // console.log(response.data.data.ticket.data.defaultFieldsData.approval_list.value.length)
        setApprovalCount(response.data.data.ticket.data.defaultFieldsData.approval_list.value.length)
      }
      if (type === 'ec-deviation' || type === 'pc-template') {
        // setEcsCount(response.data.data.ticket.data.defaultFieldsData.ec_title.value.length)
        setEcsList(response.data.data.ticket.data.defaultFieldsData.select_ec.value)

      }
      if (type === 'pc-template') {
        setPartsCount(response.data.data.parts_count)
        setApprovalCount(response.data.data.ticket.data.defaultFieldsData)

      }
      if (type === 'pci-view') {
        setPartsCount(response.data.data.parts_count)
        // setApprovalCount(response.data.data.ticket.data.defaultFieldsData)

      }
      if (type === 'ec-template') {
        // setEcsCount(response.data.data.ticket.data.defaultFieldsData.ec_title.value.length)
        setEcsList(1)
        setSelectedEcType(response.data.data.ticket.ec_type)
        if (response.data.data.ticket.ec_type === 'structuring_ec' || type === 'pci-view') {
          setSelectedViewType(defaultViewState)
        }

      }
      if (type === 'pci-view') {
        // setEcsCount(response.data.data.ticket.data.defaultFieldsData.ec_title.value.length)


        setSelectedViewType(defaultViewState)


      }
      setTicketId(response.data.data.ticket._id)

      setResponseData(response.data.data.ticket)


      let members
      if (type !== 'pci-view') {
        if (type === 'template') {
          if (response.data.data.ticket.data.defaultFieldsData.watchers) {
            members = [
              response.data.data.ticket.data.defaultFieldsData.owner.value.member_id,
              response.data.data.ticket.data.defaultFieldsData.assignee.value.member_id,
              ...response.data.data.ticket.data.defaultFieldsData.watchers.value.map((item) => item.member_id)
            ];
          } else {
            members = [
              response.data.data.ticket.data.defaultFieldsData.owner.value.member_id,
              response.data.data.ticket.data.defaultFieldsData.assignee.value.member_id,

            ];
          }

        }
        else {
          members = [
            response.data.data.ticket.data.defaultFieldsData.owner.value.member_id,
            response.data.data.ticket.data.defaultFieldsData.assignee.value.member_id,

          ];
        }
      }


      if (type !== 'template') {
        setEngineeringDetails(response.data.data.ticket.data.defaultFieldsData)
      }

      const uniqueMemberIdsSet = new Set(members);
      const uniqueMemberIds = [...uniqueMemberIdsSet];



      setNotifyIds(uniqueMemberIds)
      setDepartmentId(type === 'template' ? response.data.data.ticket.department_id : '')
      const dataNeeded = response.data.data;
      setDepartment(type === 'template' ? dataNeeded.ticket.departmentName : '');
      setEditable(response.data.data.should_edit);
      setShouldRevert(response.data.data.should_revert)
      setEditableComment(response.data.data.should_comment)
      const neededData = dataNeeded.ticket.data.defaultFieldsData;
      if (type === 'template') {
        setTicketName(neededData.ticket_title.value);
      }
      if (type === 'ec-template') {
        setApprovalMemberView(response.data.data.should_approve);

        setEcApprovalStatus(response.data.data.ticket.approval_status)
        setMemberApproved(response.data.data.have_approved)
        setTicketName(neededData.ec_title.value);

      }
      if (type === 'ec-deviation') {
        setApprovalMemberView(response.data.data.should_approve);

        setEcApprovalStatus(response.data.data.ticket.approval_status)
        setMemberApproved(response.data.data.have_approved)
        setTicketName(neededData.deviation_title.value);

      } if (type === 'pc-template') {
        setApprovalMemberView(response.data.data.should_approve);
        setResultApprovalMemberView(response.data.data.should_result_approve)

        setEcApprovalStatus(response.data.data.ticket.pc_status)
        setMemberApproved(response.data.data.have_approved)
        setTicketName(neededData.pc_title.value);

      }
      if (type === 'pci-view') {
        setApprovalMemberView(response.data.data.should_approve);
        setEcApprovalStatus(response.data.data.ticket.product_approve_status)
        setMemberApproved(response.data.data.have_approved)
        setTicketName(neededData.product_title.value);

      }
      if (type === 'ec-template') {
        const filteredDefaultFieldsData = Object.fromEntries(
          Object.entries(dataNeeded.ticket.data.defaultFieldsData)
            .filter(([fieldName, field]) => fieldName !== 'end Date' && fieldName !== 'select ec')
        );
        setDefaultDetails(filteredDefaultFieldsData);
      } else {
        setDefaultDetails(dataNeeded.ticket.data.defaultFieldsData);

      }






      setPrority(type === 'template' ? neededData.priority.value : '');
      // setDueDate(neededData.due_date.value);
      setSeqId(type === 'template' ? dataNeeded.ticket.sequence_id : '');


      setTicketDescription(neededData.description.value);
      // console.log(neededData.description.value)
      setAttachedFiles(neededData.attachment.value);
      setTicketDetails(dataNeeded.ticket.data.customFieldsData);
      setCommentLabel(neededData.comments.fieldName);
      setMemberPhoto(dataNeeded);


      // Get the date part in YYYY-MM-DD format
      // const formattedDate = dateObject
      //   ? dateObject.toISOString().split("T")[0]
      //   : "";


      // setCreatedOnDate(formattedDate);
      // setDataCreatedOnDate(formattedDatecreatedAt)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching options data:", error);
      setLoading(false);
    }
  };



  const handleMouseEnter = (index, comment, updatedAt) => {
    const currentTime = new Date().getTime();
    const updatedAtTime = new Date(updatedAt).getTime();
    const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds

    const memberDetails = JSON.parse(localStorage.getItem("memberDetails"));


    if (editableComment && comment.memberId === memberDetails.id && currentTime - updatedAtTime <= fifteenMinutes) {
      setShowEditButtons((prevButtons) => ({ ...prevButtons, [index]: true }));
    }
  };

  const handleMouseLeave = (index) => {
    setShowEditButtons((prevButtons) => ({ ...prevButtons, [index]: false }));
  };
  const handleTextareaClick = () => {
    setShowButtons(true);
  };
  const HandleDescription = () => {
    setShowButtonsDescrip(true);
  };
  const HandleTitle = () => {
    setShowButtonsText(true);
  };
  const ChangeDescription = () => {
    if (editDescription === "") {
      setShowButtonsDescrip(false);
    } else {
      const newCommonObj = {
        description: { ...defaultDetails.description, value: editDescription },
      };
      const prevCommonObj = {
        description: {
          ...defaultDetails.description,
          value: ticketDescription,
        },
      };
      const prevObj = { ...prevCommonObj };
      const newObj = { ...newCommonObj };
      setPreviousData(prevObj);
      setNewData(newObj);
      setShowButtonsText(false);
      setChangeDetails(true);
    }
  };
  const ChangeEngeeringDescription = (e, engineering, newEngineering, engineering_details, engineertype) => {
    e.stopPropagation()
    let defaultData;
    let newCommonObj;
    let prevCommonObj;
    if (engineertype === 'engineering') {
      defaultData = defaultDetails.engineering_details
      newCommonObj = {
        engineering_details: { ...defaultDetails.engineering_details, value: newEngineering },
      };
      prevCommonObj = {
        engineering_details: {
          ...defaultDetails.engineering_details,
          value: engineering,
        },
      };
    } else if (engineertype === 'production') {
      defaultData = defaultDetails.production_details
      newCommonObj = {
        production_details: { ...defaultDetails.production_details, value: newEngineering },
      };
      prevCommonObj = {
        production_details: {
          ...defaultDetails.production_details,
          value: engineering,
        },
      };
    } else if (engineertype === 'service') {
      defaultData = defaultDetails.service_details
      newCommonObj = {
        service_details: { ...defaultDetails.service_details, value: newEngineering },
      };
      prevCommonObj = {
        service_details: {
          ...defaultDetails.service_details,
          value: engineering,
        },
      };
    }


    const prevObj = { ...prevCommonObj };
    const newObj = { ...newCommonObj };
    setPreviousData(prevObj);
    setNewData(newObj);
    setShowButtonsText(false);
    setChangeDetails(true);

  };
  const ChangeTitle = () => {
    if (editTicketName === "") {
      setShowButtonsText(false);

    } else {
      let newCommonObj, prevCommonObj
      if (type === 'ec-template') {
        newCommonObj = {
          ec_title: { ...defaultDetails.ec_title, value: editTicketName },
        };
        prevCommonObj = {
          ec_title: { ...defaultDetails.ec_title, value: ticketName },
        };
      }
      if (type === 'ec-deviation') {
        // console.log(defaultDetails)
        newCommonObj = {
          deviation_title: { ...defaultDetails.deviation_title, value: editTicketName },
        };
        prevCommonObj = {
          deviation_title: { ...defaultDetails.deviation_title, value: ticketName },
        };
      } if (type === 'pc-template') {
        // console.log(defaultDetails)
        newCommonObj = {
          pc_title: { ...defaultDetails.pc_title, value: editTicketName },
        };
        prevCommonObj = {
          pc_title: { ...defaultDetails.pc_title, value: ticketName },
        };
      }
      if (type === 'pci-view') {
        // console.log(defaultDetails)
        newCommonObj = {
          product_title: { ...defaultDetails.product_title, value: editTicketName },
        };
        prevCommonObj = {
          product_title: { ...defaultDetails.product_title, value: ticketName },
        };
      }
      if (type === 'template') {
        newCommonObj = {
          ticket_title: { ...defaultDetails.ticket_title, value: editTicketName },
        };
        prevCommonObj = {
          ticket_title: { ...defaultDetails.ticket_title, value: ticketName },
        };
      }
      console.log(prevCommonObj)
      const prevObj = { ...prevCommonObj };
      const newObj = { ...newCommonObj };
      setPreviousData(prevObj);
      setNewData(newObj);
      setShowButtonsText(false);
      setChangeDetails(true);
    }
  };
  const handleCommentsCancel = () => {
    setTicketDescription(ticketDescription);
    // setTicketName(ticketName)
    setShowButtons(false);
    setShowButtonsDescrip(false);
    setShowButtonsText(false);
    setEngineeringEditing(false)
  };
  const handleEngineeringCancel = (e) => {
    e.stopPropagation()

    setEngineeringEditing(false)
  };
  const handleTicketCancel = () => {
    setShowButtonsText(false)
  }
  const getFileFormat = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toUpperCase(); // Return uppercase format
    }
    return "Unknown";
  };

  let inputRef = React.createRef();

  const handleFileDrop = async (event) => {
    event.preventDefault();

    // Check if the user is an admin
    if (!editable) {
      // If not an admin, return without handling the file drop
      return;
    }

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      handleFile(file);
    }
  };


  const saveComments = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      if (comments === "") {
        toast.info("comment should not be empty", toastStyle);
      } else {

        await axios.post(
          `${BASE_URL}/v1/comment/create-comment/${sequence_id}`,
          {
            comments, ticket_id: ticketId, ticket_title: ticketName,
            member_ids: notifyIds
          },
          { headers }
        );
        setShowButtons(false);
        setComment("");
        getComments();

      }
    } catch (error) {
      console.log(error);
    }
  };

  const getComments = async () => {
    try {



      const headers = {
        "x-auth-token": Cookies.get("token"),
      };

      let getComment

      getComment = await axios.get(
        `${BASE_URL}/v1/comment/get-comment/${sequence_id}`,
        { headers }
      );



      const renderData = getComment.data.data.commentsDetails;

      setShowComment(renderData);
    } catch (error) {
      console.log(error);
    }
  };


  const handleEditChange = (e, index) => {
    e.stopPropagation()
    const newComments = [...showComment];
    newComments[index].comments = e.target.value;
    setShowEditSaveButtons(true);
    setShowComment(newComments);
  };
  const handleCancelEdit = () => {
    setShowEditSaveButtons(false);
    setEditingIndex(false);
  };
  const editComments = async (commentId, comment) => {

    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };

      const requestData = {
        comments: comment,
        commentId: commentId,
      };

      await axios.put(
        `${BASE_URL}/v1/comment/edit-comment`,
        requestData,
        { headers }
      );


      setShowEditSaveButtons(false);
      setEditingIndex(-1);
      getComments(); // Assuming this function fetches comments after editing
    } catch (error) {
      console.log(error);
    }
  };

  const deleteComment = async (id) => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      await axios.delete(
        `${BASE_URL}/v1/comment/delete-comment`,
        {
          headers,
          data: {
            _id: id, sequence_id: seqId,
            [type === 'ec-template' ? 'ec_id' : 'dev_id']: ticketId, ticket_title: ticketName,
            member_ids: notifyIds,
          },
        }
      );

      getComments();
    } catch (error) {
      console.log(error);
    }
  };




  const handleDragOver = (event) => {
    event.preventDefault();

  };

  const handleFile = async (file) => {
    const fileSizeMB = file.size / (1024 * 1024); // size in MB
    if (fileSizeMB > 2) {
      toast.warning("File size cannot be more than 2 MB", toastStyle); // Notify the user
      return; // Exit the function
    }
    //fetch pre signed URL
    setIsLoading(true);
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const preSignedURL = await axios.post(
        `${BASE_URL}/v1/member/get-presigned-url`,
        { bucket_name: TICKET_ATTACHMENT_BUCKET, file: file.name },
        { headers: headers }
      );
      if (
        preSignedURL.data.meta.code === 200 &&
        preSignedURL.data.meta.message === "SUCCESS" &&
        preSignedURL.data.data.url
      ) {
        //upload to s3
        await axios.put(preSignedURL.data.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        // file.name = preSignedURL.data.data.file_name;
        // handleFieldChange("file", file.name);
        setFileFormat(getFileFormat(file.name));
        const fieldName = defaultDetails["attachment"].fieldName;
        // let value = [];
        const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");

        const newCommonObj = {
          [fieldNameKey]: {
            ...defaultDetails["attachment"],
            value: [
              ...defaultDetails["attachment"].value,
              { name: file.name, key: preSignedURL.data.data.key, action: 'added' },
            ],
          },
        };
        const prevObj = { [fieldNameKey]: { ...defaultDetails["attachment"] } };
        const newObj = { ...newCommonObj };
        setPreviousData(prevObj);
        setNewData(newObj);

        setChangeDetails(true);
        // setUploadedFiles((prevUploadedFiles) => [
        //   ...prevUploadedFiles,
        //   { name: file.name, key: preSignedURL.data.data.key },
        // ]);
      } else {
        toast.error("Error generating signed URL", toastStyle);
      }
    } catch (e) {
      console.log(e);
      toast.error("Error in uploading file", toastStyle);
    }
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];


    handleFile(file);


  };


  const options1 = [
    { value: "P0", priority: "P0", label: "P0", color: "#DA2370" },
    { value: "P1", priority: "P1", label: "P1", color: "#EE962F" },
    { value: "P2", priority: "P2", label: "P2", color: "#00B884" },
    { value: "P3", priority: "P3", label: "P3", color: "#5696F2" },
    { value: "P4", priority: "P4", label: "P4", color: "#5696F2" },
  ];
  const getOptionColor = (priority) => {
    const selectedOption = options1.find((option) => option.value === priority);
    return selectedOption ? selectedOption.color : "#FFFFFF";
  };
  // Security
  // const options2 = [
  //   { value: "S1", security: "S1", label: "S1", color: "#EE962F" },
  //   { value: "S2", security: "S2", label: "S2", color: "#00B884" },
  //   { value: "S3", security: "S3", label: "S3", color: "#5696F2" },
  //   { value: "S4", security: "S4", label: "S4", color: "#DA2370" },
  // ];
  // const getSecurityOptionColor = (security) => {
  //   const selectedOption = options2.find((option) => option.value === security);
  //   return selectedOption ? selectedOption.color : "#FFFFFF";
  // };
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      overflowY: "auto",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white",
      color: state.isSelected ? "black" : "inherit",
    }),
    optionLabel: {
      display: "flex",
      alignItems: "center",
    },
  };

  useEffect(() => {
    fetchMembers();

  }, []);
  useEffect(() => {
    getComments();
  }, []);
  //  getComments();
  const fetchMembers = async () => {
    try {
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/setting/get-mem", {
        headers: headers,
      });





      setMemberDetails(response.data.data.memberList);
      // console.log(ticketId)
      let watcherResponse
      if (type === 'template') {
        watcherResponse = await axios.get(
          `${BASE_URL}/v1/ticket/get-watchers-list/${sequence_id}`,
          { headers }
        );
        setWatchersDetails(watcherResponse.data.data.nonWatchersList);
      }


    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };






  const getFileIcon = (fileName) => {
    // console.log(fileName)
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div

          style={{ overflow: 'hidden', border: 'none', }} className="extension-type-div">
          <iframe
            src={PHOTO_LINK + fileName}
            title="CSV Preview"
          //   width= '75px'
          // height= '75px'
          // border= 'none'
          // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
          // scrolling="no"
          />
        </div>

      );
    }
    else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "jpg" || extension === 'png' || extension === 'jpeg' || extension === 'svg') {
      return (
        <img src={PHOTO_LINK + fileName} alt=""
          className="png-img-div" />
      );
    }
    else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return <img src={`${ASSET_PREFIX_URL}filesImage.png`} alt="File" width="75px"
        height="75px" />;
    }
  };
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // const handleOpenChange = () => {
  //   setChangeDetails(true);
  // };
  const handleCloseChange = () => {
    setChangeDetails(false);
  };

  const handleCloseDelete = () => {
    setDeletepopup(false);
  };


  const toggleEditable = (key) => {

    const fieldName = ticketDetails[key].fieldName;
    const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");

    // Use the field name as the key for textEditing state
    setTextEditable(true);
    setSelectedInputKey(fieldNameKey);
  };

  // const [linkInputs, setLinkInputs] = useState({});

  const toggleLinkEditable = (key) => {
    const fieldName = ticketDetails[key].fieldName;
    const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");
    // Toggle the edit mode for the specific link input field
    setLinkInput(prevInputs => ({
      ...prevInputs,
      [key]: !prevInputs[key] // Toggle the edit mode for the specific key
    }));
    setSelectedInputKey(fieldNameKey);
  };
  const handleSave = (key) => {

    const customDetails = ticketDetails[key];
    if (customDetails) {

      const fieldName = customDetails.fieldName;
      const fieldNameKey = fieldName
        .replace(/ /g, "_")
        .replace(/^./, (match) => match.toLowerCase());
      let value;

      if (fieldNameKey === key) {
        value = customEditing;
      }
      console.log(customEditing)
      const newCommonObj = {
        [fieldNameKey]: { ...ticketDetails[key], value: customEditing },
      };

      const prevObj = { [fieldNameKey]: { ...ticketDetails[key] } };
      const newObj = { ...newCommonObj };

      setPreviousData(prevObj);
      setNewData(newObj);
      setChangeDetails(true);
    }
    toggleEditable(key);
    setChangeDetails(true); // Toggle editable state to false
  };
  const handleCancel = (key) => {
    if (key) {
      setCustomEditing(ticketDetails[key].value);
      setSelectedInputKey(false);

    }
    // window.location.reload();
  };
  const handleLinkCancel = (key) => {
    if (key) {
      setLinkInput(prevInputs => ({
        ...prevInputs,
        [key]: !prevInputs[key] // Toggle the edit mode for the specific key
      }));
      setSelectedInputKey(false);

    }
    // window.location.reload();
  };
  const handleChange = (key, selected) => {

    const defaultKeys = defaultDetails[key];

    if (defaultKeys) {
      const fieldName = defaultKeys.fieldName;

      const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");
      let value;

      if (defaultKeys.inputType === "person") {
        let photo = selected.label?.props?.children[0]?.props?.src;
        if (photo && photo.includes) {
          if (photo.includes(PHOTO_LINK)) {
            photo = photo.replace(PHOTO_LINK, '');
          } else {
            photo = photo.replace(DEFAULT_PHOTO, '');
          }
        }

        value = {
          photo: photo,
          fullName: selected.label?.props?.children[1],
          member_id: selected.value,
        };
      } else if (
        defaultKeys.fieldName === "Priority" ||
        defaultKeys.fieldName === "Security"
      ) {
        value = selected.label?.props?.children;
      } else if (defaultKeys.inputType === "date") {
        const newValue = selected.target.value;
        value = newValue;
      } else {
        value = selected.value;
      }

      const newCommonObj = {
        [fieldNameKey]: { ...defaultDetails[key], value: value },
      };
      const prevObj = { [fieldNameKey]: { ...defaultDetails[key] } };
      const newObj = { ...newCommonObj };

      setPreviousData(prevObj);
      setNewData(newObj);
      setChangeDetails(true);
    }
  };

  const handleCustomChange = (key, selected) => {
    const customDetails = ticketDetails[key];

    if (customDetails) {
      const fieldName = customDetails.fieldName;
      const fieldNameKey = fieldName
        .replace(/ /g, "_")
        .replace(/^./, (match) => match.toLowerCase());
      let value;

      if (customDetails.inputType === "person") {
        const photo =
          selected.label?.props?.children[0]?.props?.src?.replace(
            PHOTO_LINK,
            ""
          ) || ""; // Set a default value if photo is undefined
        const fullName = selected.label?.props?.children[1];
        const memberId = selected.value;

        value = {
          photo: photo,
          fullName: fullName,
          member_id: memberId,
        };
      } else if (customDetails.inputType === "single_select") {
        value = selected;
      } else if (customDetails.inputType === "date") {
        const newValue = selected.target.value;
        value = newValue;
      }

      // Create a common object structure
      const newCommonObj = {
        [fieldNameKey]: { ...ticketDetails[key], value: value },
      };
      const prevObj = { [fieldNameKey]: { ...ticketDetails[key] } };
      const newObj = { ...newCommonObj };

      setPreviousData(prevObj);
      setNewData(newObj);
      setChangeDetails(true);
    }



  };

  const watchersChange = (key, selected) => {
    const fieldName = defaultDetails[key].fieldName;
    const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");

    if (selected.length === 0) {
      toast.error("Watchers can't be empty", toastStyle);
      return; // Prevent further execution of the function
    }

    const labelValue = selected.map((item) => {
      let photo = '';
      let member_id = '';
      let fullName = '';

      if (item.label && item.label.props && item.label.props.children) {
        const children = item.label.props.children;

        if (children[0] && children[0].props && children[0].props.src) {
          photo = children[0].props.src;
          if (photo.includes(PHOTO_LINK)) {
            photo = photo.replace(PHOTO_LINK, '');
          } else {
            photo = photo.replace(DEFAULT_PHOTO, '');
          }
        }

        if (children[2] && children[2].props && children[2].props.children) {
          member_id = children[2].props.children;
        }

        if (children[1]) {
          fullName = children[1];
        }
      }

      return {
        photo: photo,
        member_id: member_id,
        fullName: fullName,
      };
    });



    const newCommonObj = {
      [fieldNameKey]: { ...defaultDetails[key], value: [...labelValue] },
    };
    const prevObj = { [fieldNameKey]: { ...defaultDetails[key] } };
    const newObj = { ...newCommonObj };

    setPreviousData(prevObj);
    setNewData(newObj);

    setChangeDetails(true);
  };



  const handleMultiSelectChange = (key, selected) => {

    const fieldName = ticketDetails[key].fieldName;
    // let value = [];
    const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");

    let selectedMember = selected.map((item) => item.value);

    const value = [...selectedMember];

    const newCommonObj = {
      [fieldNameKey]: { ...ticketDetails[key], value: value },
    };
    const prevObj = { [fieldNameKey]: { ...ticketDetails[key] } };
    const newObj = { ...newCommonObj };
    setPreviousData(prevObj);
    setNewData(newObj);

    setChangeDetails(true);
  };

  const handleDefaultMultiSelectChange = (key, selected) => {

    const fieldName = defaultDetails[key].fieldName;
    // let value = [];
    let selectedMember
    const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");
    if (key === 'select_ec') {
      selectedMember = selected.map((item) => ({ ec_id: item.value, ec_title: item.label }));

    } else {
      selectedMember = selected.map((item) => item.value);
    }



    const value = [...selectedMember];
    console.log(value)
    const newCommonObj = {
      [fieldNameKey]: { ...defaultDetails[key], value: value },
    };
    const prevObj = { [fieldNameKey]: { ...defaultDetails[key] } };
    const newObj = { ...newCommonObj };
    setPreviousData(prevObj);
    setNewData(newObj);

    setChangeDetails(true);
  };


  const handleDepartmentNav = () => {
    if (type === 'template') {
      nav(`/department-ticket/${department}/${departmentId}`)
    } else if (type === 'ec-template') {
      nav(`/all-ec-list`)
    } else if (type === 'pc-template') {
      nav('/pc-list')
    } else if (type === 'pci-view') {
      nav('/pci-list')
    } else {
      nav('/all-deviation-list')
    }

  }
  // Single function to handle both start and end date changes
  const handleDateRangeChange = (key, type, dateValue,) => {
    const customDetails = defaultDetails[key];
    const fieldName = customDetails.fieldName;
    const fieldNameKey = fieldName
      .replace(/ /g, "_")
      .replace(/^./, (match) => match.toLowerCase());

    // Update defaultDetails state
    setDefaultDetails((prevDetails) => ({
      ...prevDetails,
      [key]: {
        ...prevDetails[key],
        value: {
          ...prevDetails[key].value,
          [type]: dateValue // Dynamically update 'start' or 'end' based on the type passed
        }
      }
    }));

    // Create previous state object (before the change)
    const prevObj = {
      [fieldNameKey]: {
        ...defaultDetails[key],
        value: { ...defaultDetails[key].value }
      }
    };

    // Create new state object (after the change)
    const newCommonObj = {
      [fieldNameKey]: {
        ...defaultDetails[key],
        value: {
          ...defaultDetails[key].value,
          [type]: dateValue // This will reflect the updated value for start or end
        },
        changedField: type // Track which field ('start' or 'end') was changed
      }
    };

    // Log the previous and new state
    console.log(prevObj, newCommonObj);

    // Set previous and new data for further use
    setPreviousData(prevObj); // This will hold the previous values before the change
    setNewData(newCommonObj); // This will hold the new values after the change
    setChangeDetails(true);   // Trigger any other change handling logic
  };
  const handleCustomDateRangeChange = (key, type, dateValue,) => {
    const customDetails = ticketDetails[key];
    const fieldName = customDetails.fieldName;
    const fieldNameKey = fieldName
      .replace(/ /g, "_")
      .replace(/^./, (match) => match.toLowerCase());

    // Update defaultDetails state
    setTicketDetails((prevDetails) => ({
      ...prevDetails,
      [key]: {
        ...prevDetails[key],
        value: {
          ...prevDetails[key].value,
          [type]: dateValue // Dynamically update 'start' or 'end' based on the type passed
        }
      }
    }));

    // Create previous state object (before the change)
    const prevObj = {
      [fieldNameKey]: {
        ...ticketDetails[key],
        value: { ...ticketDetails[key].value }
      }
    };

    // Create new state object (after the change)
    const newCommonObj = {
      [fieldNameKey]: {
        ...ticketDetails[key],
        value: {
          ...ticketDetails[key].value,
          [type]: dateValue // This will reflect the updated value for start or end
        },
        changedField: type // Track which field ('start' or 'end') was changed
      }
    };

    // Log the previous and new state
    console.log(prevObj, newCommonObj);

    // Set previous and new data for further use
    setPreviousData(prevObj); // This will hold the previous values before the change
    setNewData(newCommonObj); // This will hold the new values after the change
    setChangeDetails(true);   // Trigger any other change handling logic
  };


  const deleteAttachment = async (key) => {

    try {

      const headers = {
        'x-auth-token': Cookies.get('token'),
      };
      await axios.post(BASE_URL + '/v1/ticket/delete-attachment', { ticketId, key }, { headers: headers });
      // toast.info(`${key} has been removed `, toastStyle);
      // fetchFormDetails();
    } catch (error) {
      console.log(error);
    }


  }
  // make-approve
  const makeApprove = async () => {
    if (ecApprovalStatus === '') {
      if (type === 'ec-template') {
        toast.info("EC havent sent for approve", toastStyle);
      } else {
        toast.info("Deviation havent sent for approve", toastStyle);
      }

      // alert('ec havent sent for approve')
    }
    else {
      // if (type !== 'pci-view') {
      // try {

      //   const headers = {
      //     'x-auth-token': Cookies.get('token'),
      //   };

      //   let endpoint, idType;
      //   if (type === 'ec-template') {
      //     console.log(type)
      //     endpoint = 'ec-template'
      //     idType = 'ec_id'
      //   } else if (type === 'pci-view') {
      //     endpoint = 'manufac-bom'
      //     idType = 'product_id'
      //   } else {
      //     endpoint = 'ec-deviations'
      //     idType = 'dev_id'

      //   }
      //   await axios.post(`${BASE_URL}/v1/${endpoint}/make-approve`, {
      //     sequence_id,
      //     [idType]: ticketId,
      //   }, { headers: headers });
      //   // toast.info(`${key} has been removed `, toastStyle);
      //   // fetchFormDetails();
      //   setMemberApproved('Released')
      //   window.location.reload()
      // } catch (error) {
      //   console.log(error);
      // }
      // } else if (type === 'pci-view') {
      setOpenApprover(!openApprover)
      // }

    }



  }
  const openChangeLogsPopUP = async (file) => {


    const fieldName = defaultDetails["attachment"].fieldName;

    const fieldNameKey = fieldName.toLowerCase().replace(/ /g, "_");


    const existingFileIndex = defaultDetails["attachment"].value.findIndex(f => f.key === file.key);


    if (existingFileIndex !== -1) {
      defaultDetails["attachment"].value[existingFileIndex].action = 'remove';
    } else {

      defaultDetails["attachment"].value.push({ name: file.name, key: file.key, action: 'remove' });
    }

    const prevObj = { [fieldNameKey]: { ...defaultDetails["attachment"] } };
    const newObj = { ...prevObj };
    setPreviousData(prevObj);
    setNewData(newObj);

    setAttachmentKey(file.key)
    setAttachmentDetete(true)
    setChangeDetails(true);
  }

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen);
  };


  const [selectedMembers, setSelectedMembers] = useState([]);


  function renderCounts(items) {
    const counts = {
      pending: 0,
      reject: 0
    };

    // Count occurrences of "pending" and "rejected" statuses
    items.forEach(item => {
      if (item.status === "pending" || item.status === "reject") {
        counts[item.status]++;
      }
    });

    // Create a string with counts separated by commas
    const countsString = Object.entries(counts).map(([status, count]) => `${count} ${status}`).join(', ');

    return countsString;
  }

  const [showDetails, setShowDetails] = useState({
    engineering: false,
    production: false,
    service: false,
  });

  const toggleDetails = (section) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const handleEditingEngineering = (e, section) => {
    e.stopPropagation()
    setEngineeringEditing((prevState) => ({
      ...prevState,
      [section]: true,
    }));

    setShowDetails((prevState) => ({
      ...prevState,
      [section]: true,
    }));
  }
  const handleEngineeringChange = (e, section) => {
    e.stopPropagation()
    setEditEngineeringDetails((prevState) => ({
      ...prevState,
      [section]: e.target.value,
    }));
    setShowDetails((prevState) => ({
      ...prevState,
      [section]: true,
    }));

  }
  const handleRevert = async (action) => {
    console.log(action)
    try {
      const headers = {
        'x-auth-token': Cookies.get('token'),
      };
      if (type !== 'pc-template') {
        let endpoint, url, ticketType;
        if (type === 'ec-template') {
          endpoint = 'ec-template'
          url = 'revert-ec'
          ticketType = 'ec_id'
        } else if (type === 'pci-view') {
          endpoint = 'manufac-bom'
          url = 'revert-pci'
          ticketType = 'product_id'
        } else {
          endpoint = 'ec-deviations'
          url = 'revert-ec'
          ticketType = 'dev_id'

        }

        await axios.post(`${BASE_URL}/v1/${endpoint}/${url}`,
          { sequence_id, [ticketType]: ticketId, }, { headers: headers });
        fetchFormDetails()
      } else if (type === 'pc-template') {
        await axios.post(`${BASE_URL}/v1/prod-change/revert-pc`,
          { sequence_id, pc_id: ticketId, type: action === 'revert-trail' ? 'trial-approvers' : 'plan-approvers' }, { headers: headers });
        fetchFormDetails()
      } else if (type === 'pci-view') {
        await axios.post(`${BASE_URL}/v1/manufac-bom/revert-pci`,
          { sequence_id, product_id: ticketId, }, { headers: headers });
        // fetchFormDetails()
      }

      // window.location.reload()

    } catch (error) {
      console.log(error)
    }
  }




  const handleOpenApproveList = (action) => {

    if (action === 'revert' || action === 'revert-plan' || action === 'revert-trail') {
      handleRevert(action)
      getApprovals()

    } if (action === 'reject' || action === 'reject-plan' || action === 'reject-trail') {
      setOpenApproveList(action)
    }
    // getApprovalListMembers()
  }



  const handeSendForApproval = async (action) => {
    if (type !== 'pc-template') {
      if ((type === 'ec-template' && selectedEcType === 'release_ec') || type === 'ec-deviation') {
        if (partsCount !== 0 && ecsList.length !== 0) {

          try {
            setLoading(true)
            const headers = {
              "x-auth-token": Cookies.get("token"),
            };


            const approvalResponse = await axios.get(
              `${BASE_URL}/v1/ec-template/validate-part-num-mapping`,
              {
                headers,
                params: {
                  [type === 'ec-template' ? 'ec_id' : 'dev_id']: ticketId
                }
              }
            );



            console.log(approvalResponse.data.data)

            if (approvalResponse.data.data.parts_to_be_added.length > 0) {
              console.log('parts to be added');
              setOpenPartsAdded(!openPartsAdded);
            }
            else if (approvalResponse.data.data.err_parts.length > 0) {
              console.log('error parts');
              setPartsToBeAdded(approvalResponse.data.data.parts_to_be_added)
              setErrorParts(approvalResponse.data.data.err_parts)
              setOpenErrorsParts(!openErrorsParts);
            }
            else if (approvalResponse.data.data.parts_to_be_added.length < 1 && approvalResponse.data.data.err_parts.length < 1) {
              console.log('no parts to be added or error parts');
              setOpenApproveList(action);
            }
            setLoading(false)
          } catch (error) {
            console.log(error)
            // setLoading(false)
          } // Wait for validation to complete

        } else if (partsCount === 0) {

          toast.error("Please add the part numbers and send for approval.");
          setSelectedSection('parts')
        } else if (type === 'ec-deviation') {
          if (ecsList.length === 0) {
            setSelectedSection('ec-list')
            toast.error("Please add EC to send for approval.");
          }
        }
      } else if ((type === 'ec-template' && selectedEcType === 'structuring_ec')) {

        try {
          setLoading(true)
          const headers = {
            "x-auth-token": Cookies.get("token"),
          };


          const approvalResponse = await axios.get(
            `${BASE_URL}/v1/ec-template/validate-structured-ec-part-num-mapping`,
            {
              headers,
              params: {
                ec_sequence_id: sequence_id
              }
            }
          );



          console.log(approvalResponse.data.data)

          if (approvalResponse.data.data.parts_to_be_added.length > 0) {
            console.log('parts to be added');
            setOpenPartsAdded(!openPartsAdded);
          }
          else if (approvalResponse.data.data.err_parts.length > 0) {
            console.log('error parts');
            setPartsToBeAdded(approvalResponse.data.data.parts_to_be_added)
            setErrorParts(approvalResponse.data.data.err_parts)
            setOpenErrorsParts(!openErrorsParts);
          }
          else if (approvalResponse.data.data.parts_to_be_added.length < 1 && approvalResponse.data.data.err_parts.length < 1) {
            console.log('no parts to be added or error parts');
            setOpenApproveList(action);
          }
          setLoading(false)
        } catch (error) {
          console.log(error)
          // setLoading(false)
        }
        // setOpenApproveList('approvelist')
      } else if (type === 'pci-view') {

        setOpenApproveList(action);

      }
    } else if (type === 'pc-template') {
      setOpenApproveList(action)
    }


  };


  const handlecloseErrorPopUp = () => {
    setOpenPartsAdded(false);
    setOpenErrorsParts(false)
  }
  const handleclosePartsAddPopUp = () => {

    setOpenPartsAdded(false);
  }


  const handleOpenApproveListClose = () => {
    setOpenPartsAdded(false);
    setOpenApproveList(false)
  }

  useEffect(() => {
    if (selectedSection === 'approval' ||
      selectedApproverSection === 'test-approval' ||
      selectedApproverSection === 'res_approval')
      if (searcingApprovals !== '') {


        getApprovalMembers();
      }
      else if(searcingApprovals === '') {
        getApprovals();
      }
  }, [searcingApprovals, selectedSection,searchCondition, selectedApproverSection]);
  const handleSeachApprovals = (e) => {
    const value = e.target.value;
    setSearcingApprovals(e.target.value);
    if (e.target.value !== '') {
      setSearchCondition(true)
      setApprovalListOfMembers([])
      setApprovalLoading(true)
      getApprovalMembers();
      // debounce(getApprovalMembers)();
    } else if(e.target.value === '') {
      setSearchCondition(false)
      // setApprovalListOfMembers([])
      setApprovalLoading(true)
    }
    console.log(value)
  };
  const getApprovalMembers = async () => {
    try {
      setApprovalLoading(true)
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      if (type !== 'pc-template') {
        let endpoint;
        if (type === 'ec-template') {
          endpoint = 'ec-template'
        } else if (type === 'pci-view') {
          endpoint = 'manufac-bom'
        } else if (type === 'ec-deviation') {
          endpoint = 'ec-deviations'
        }
        const approvalResponse = await axios.get(
          `${BASE_URL}/v1/${endpoint}/get-approve-list/${sequence_id}`,
          { headers, params: { search: searcingApprovals } }
        );

        setApprovalListOfMembers(approvalResponse.data.data.nonApprovalList);
      } else if (type === 'pc-template') {
        const approvalResponse = await axios.get(
          `${BASE_URL}/v1/prod-change/get-pc-approvers`,
          {
            headers, params: {
              sequence_id, type: selectedApproverSection === 'test-approval' ? 'trial-approvers' : 'plan-approvers',
              search: searcingApprovals
            }
          }
        );
        console.log(approvalResponse)

        setApprovalListOfMembers(approvalResponse.data.data.nonApprovalList);
      }


      setApprovalLoading(false)
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  const getApprovals = async () => {
    try {
      setApprovalLoading(true);

      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      if (type !== 'pc-template') {
        let url;
        if (type === 'ec-template') {
          url = `${BASE_URL}/v1/ec-template/get-ec-approve-list`;
        } else if (type === 'ec-deviation') {
          url = `${BASE_URL}/v1/ec-deviations/get-dev-approve-list`;
        } else if (type === 'pci-view') {
          url = `${BASE_URL}/v1/manufac-bom/get-pci-approve-list`
        }
        const approvalList = await axios.get(
          url,
          {
            params: {
              sequence_id
            },
            headers
          }
        );
        console.log(approvalList.data.data.ec_approval_list);
        if (type === 'ec-template') {
          setApprovalListOfMembers(approvalList.data.data.ec_approval_list)
        } else if (type === 'ec-deviation') {
          setApprovalListOfMembers(approvalList.data.data.dev_approval_list)
        } else if (type === 'pci-view') {
          setApprovalListOfMembers(approvalList.data.data.pci_approval_list)
        }
      } else if (type === 'pc-template') {
        let url;

        url = `${BASE_URL}/v1/prod-change/get-pc-approvers-list`;


        const approvalList = await axios.get(
          url,
          {
            params: {
              sequence_id, type: selectedApproverSection === 'test-approval' ? 'trial-approvers' : 'plan-approvers'
            },
            headers
          }
        );
        setApprovalListOfMembers(approvalList.data.data.pc_approval_list)
      }


      setApprovalLoading(false);
    } catch (error) {
      // Handle error
    }
  };
  // const handleAddToApprovalList = (member) => {
  //   console.log(member)
  //   // Create a new object with the existing properties of member and add the new property

  //   console.log(updatedMember);
  // }
  const handleAddToApprovalList = async (member) => {
    setSearchCondition(false)
    const updatedMember = {
      member_id: member.entity_id,
      fullName: member.fullName,
      photo: member.photo,
      status: 'Draft'
    };
    let url


    const headers = {
      'x-auth-token': Cookies.get('token'),
    };
    let endpoint;
    if (type === 'ec-template') {
      endpoint = 'ec-template'
    } else {
      endpoint = 'ec-deviations'

    }


    await axios.post(`${BASE_URL}/v1/${endpoint}/add-more-approvals`, { sequence_id, approver: updatedMember }, { headers: headers });
    setSearcingApprovals('')
    window.location.reload()
    // getApprovals()

  };


  const handleModifyBom = () => {
    if (type === 'ec-template') {
      nav(`/ebom-modification/${sequence_id}`)
    } else if (type === 'pci-view') {
      nav(`/mbom-modification/${sequence_id}`)
    }
  }
  return (
    <>
      {/* <TicketTemplateTopNav /> */}
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="ticket-view-Page" style={{ marginTop: "60px" }} onClick={handleFalseMaking}>
            <div className="ticket-view-top">

              <div className="ticket-view-right">
                {/* <ArrowBackIcon
                  onClick={handleBack}
                  style={{ cursor: "pointer" }}
                /> */}

                {type === 'template' && <span> <span onClick={handleDepartmentNav} style={{ cursor: 'pointer', color: "#610BEE" }}>{department}</span> /</span>}
                {type === 'ec-template' && <span> <span onClick={handleDepartmentNav} style={{ cursor: 'pointer', color: "#610BEE" }}>EC</span> /</span>}
                {type === 'pc-template' && <span> <span onClick={handleDepartmentNav} style={{ cursor: 'pointer', color: "#610BEE" }}>PC</span> /</span>}
                {type === 'pci-view' && <span> <span onClick={handleDepartmentNav} style={{ cursor: 'pointer', color: "#610BEE" }}>PCI</span> /</span>}
                {type === 'ec-deviation' && <span> <span onClick={handleDepartmentNav} style={{ cursor: 'pointer', color: "#610BEE" }}>Deviations</span> /</span>}
                <span style={{ color: "#610BEE" }}>{sequence_id}</span>


              </div>
              <div className="ticket-view-left">
                <HistoryOutlinedIcon
                  onClick={handleOpenPopup}
                  style={{ cursor: "pointer" }}
                />

                {/* <img
                  src={`${ASSET_PREFIX_URL}Share-icon.svg`}
                  alt=""
                  width="24px"
                  height="24px"
                />
                <MoreVertTwoToneIcon
                  // onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                /> */}
                {/* {toggleDelete ? (
                  <div className="clonedelete">
                    <button>clone</button>
                    <button onClick={handleOpenDelete}>delete</button>
                  </div>
                ) : (
                  ""
                )
                } */}
              </div>
            </div>
            <div className="ticket-view-bottom">

              <div className="ticket-view-bottm-right">
                {(type === 'ec-template' || type === 'pci-view' || type === 'ec-deviation') &&

                  <div className="ticket-view-status-div">
                    <div className="ticket-view-status-bar">
                      <div className="ticket-view-status-name">
                        <span>In draft</span>
                        <span>Pending for approval</span>
                        <span>Approved</span>
                      </div>
                      <div className="ticket-view-status-name" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <div className="status-circle" style={{ background: '#610bee' }}></div>
                        <div className="status-line" style={{
                          background: (ecApprovalStatus === 'Pending Approval' || ecApprovalStatus === 'Released' ||
                            memberApproved === 'Pending Approval') ? '#610bee' : ''
                        }}></div>
                        <div className="status-circle" style={{
                          background: (ecApprovalStatus === 'Pending Approval' || ecApprovalStatus === 'Released' ||
                            memberApproved === 'Pending Approval') ? '#610bee' : ''
                        }}></div>
                        <div className="status-line" style={{ background: (ecApprovalStatus === 'Released') ? '#610bee' : '' }}></div>
                        <div className="status-circle" style={{ background: (ecApprovalStatus === 'Released') ? '#610bee' : '' }}></div>
                      </div>
                    </div>
                    <div className="ticket-view-status-buttons" style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                      {/* {type === 'pci-view' ? <> */}
                      {editable && <button className="ticket-view-status-btn-approve" onClick={() => handeSendForApproval('approvelist')}>Send for approval</button>}
                      {(shouldRevert && approvalMemberView) && <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert')}>Revert to draft</button>}
                      {(approvalMemberView && ecApprovalStatus === 'Pending Approval') && <button className="ticket-view-status-btn-approve" onClick={makeApprove}>Approve</button>}
                      {(approvalMemberView && ecApprovalStatus === 'Pending Approval') && <button className="ticket-view-status-btn-reject" onClick={() => handleOpenApproveList('reject')}>Reject</button>}
                      {/* </>:<> */}
                      {/* {approvalMemberView === true ? (
                      <>
                        {(memberApproved === 'Released') ? '' :
                          <>
                            {(editable && (ecApprovalStatus === 'Draft')) && <button className="ticket-view-status-btn-approve" onClick={() => handeSendForApproval('approvelist')}>Send for approval</button>}&nbsp;&nbsp;
                            {(!editable  && shouldRevert) && <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert')}>Revert to draft</button>}&nbsp;&nbsp;
                            {ecApprovalStatus === 'Pending Approval' && <button className="ticket-view-status-btn-approve" onClick={makeApprove}>Approve</button>} &nbsp;&nbsp;

                            {( ecApprovalStatus !== 'Draft') ? <button className="ticket-view-status-btn-reject" onClick={() => handleOpenApproveList('reject')}>Reject</button> : ''}</>}

                      </>
                    ) : (
                      (editable||ecApprovalStatus === 'Released') ? (
                        null
                      ) :
                        (
                          (ecApprovalStatus === 'Pending Approval') ? (
                            <button className="ticket-view-status-btn-approve" onClick={() => handleOpenApproveList('revert')}>Revert to draft</button>
                          ) : (
                            <button className="ticket-view-status-btn-approve" onClick={() => handeSendForApproval('approvelist')}>Send for approval</button>
                          )
                        )
                    )} */}
                      {/* </>} */}


                    </div>
                  </div>

                }
                {type === 'pc-template' && <ApprovingStatusContainer
                  ecApprovalStatus={ecApprovalStatus}
                  handeSendForApproval={handeSendForApproval}
                  handleOpenApproveList={handleOpenApproveList}
                  approvalMemberView={approvalMemberView}
                  editable={editable}
                  shouldRevert={shouldRevert}
                  makeApprove={makeApprove}
                  memberApproved={memberApproved}
                  resultApprovalMemberView={resultApprovalMemberView}
                  ticketId={ticketId}
                  sequence_id={sequence_id}
                  setMemberApproved={setMemberApproved}
                  ticketName={ticketName}
                  notifyIds={notifyIds}
                />
                }

                <div className="ticket-view-bottom-head">
                  {/* <span className="ticket-view-bottom-num">{seqId}</span> */}
                  {/* <span className="ticket-view-bottom-desc">{ticketName}</span> */}

                  {!editable ? (

                    <div
                      className="custom-textarea-title"
                      style={{ width: '95%' }}
                      value={editTicketName ? editTicketName : ticketName}
                    // onChange={(e) => setEditTicketName(e.target.value)}
                    // onBlur={handleSave}
                    >{ticketName}</div>
                  ) : (
                    (showButtonsText) ? (
                      <textarea
                        style={{ width: '95%' }}
                        className="custom-textarea-title"
                        value={editTicketName ? editTicketName : ticketName}
                        onChange={(e) => setEditTicketName(e.target.value)}

                      />
                    ) : (
                      <div className="custom-textarea-title" onClick={HandleTitle} style={{ width: '95%' }}>
                        <span className="ticket-view-span-title" >{ticketName}</span>
                        <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }} onClick={HandleTitle} />
                      </div>
                    )
                  )}


                  {/* {!showButtonsText && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }} onClick={HandleTitle} />} */}
                  {showButtonsText && (
                    <div className="tckt-tmpt-btns">
                      {editTicketName ? (
                        <button
                          className="tckt-tmpt-btns-save1"
                          onClick={ChangeTitle}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="tckt-tmpt-btns-save"
                          onClick={ChangeTitle}
                        >
                          Save
                        </button>
                      )}

                      <button
                        className="tckt-tmpt-btns-cnl"
                        onClick={handleTicketCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
                <div >

                  {!editable ? <span
                    className="custom-textarea"
                    style={{ width: '95%' }}
                  // value={editTicketName ? editTicketName : ticketName}
                  // onChange={(e) => setEditTicketName(e.target.value)}
                  // onBlur={handleSave}
                  >{ticketDescription}</span> : (
                    showButtonsDescrip ?
                      <textarea
                        placeholder="Add a description..."
                        className="custom-textarea"
                        style={{ width: '95%' }}
                        value={
                          editDescription ? editDescription : ticketDescription
                        }
                        disabled={!editable}
                        onClick={HandleDescription}
                        onChange={(e) => setEditDescription(e.target.value)}
                      /> :
                      <div className="custom-textarea" style={{ width: '95%' }}>
                        <span className="ticket-view-span" style={{ fontSize: '15px' }}>{ticketDescription} </span>
                        <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }} onClick={HandleDescription} />
                      </div>
                    //  <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }} onClick={HandleDescription} />
                  )}

                  {showButtonsDescrip && (
                    <div className="tckt-tmpt-btns">
                      {editDescription ? (
                        <button
                          className="tckt-tmpt-btns-save1"
                          onClick={ChangeDescription}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="tckt-tmpt-btns-save"
                          onClick={ChangeDescription}
                        >
                          Save
                        </button>
                      )}

                      <button
                        className="tckt-tmpt-btns-cnl"
                        onClick={handleCommentsCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
                {type !== 'template' &&
                  <>
                    {engineeringDetails.engineering_details && (
                      <div
                        className="eng-detail-cont"
                        // style={{ background: '#white', borderRadius: '4px', padding: '12px', display: 'flex', flexDirection: 'column', width: '92%' }}
                        onClick={() => toggleDetails('engineering')}
                      >
                        <div className="ticket-view-ec">
                          <span>{engineeringDetails.engineering_details.fieldName}</span>
                          <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" />
                        </div>
                        {showDetails.engineering && (
                          <div className="engineering-details-change"
                          //  style={{ background: '#F6F6F6', borderRadius: '4px', padding: '12px' }}
                          >
                            {(!engineeringEditing.engineering) &&
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <div className="ticket-view-span">{engineeringDetails.engineering_details.value}</div>
                                {(editable) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                  style={{ width: '15px', height: '15px' }} onClick={(e) => handleEditingEngineering(e, 'engineering')} className="editing-engineering" />}
                              </div>

                            }
                            {(engineeringEditing.engineering) &&
                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <textarea
                                  value={editEngineeringDetails.engineering ? editEngineeringDetails.engineering : engineeringDetails.engineering_details.value}
                                  className="engineering-editing-text"
                                  onChange={(e) => handleEngineeringChange(e, 'engineering')}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                {engineeringEditing.engineering && (
                                  <div className="tckt-tmpt-btns">
                                    {editEngineeringDetails.engineering ? (
                                      <button
                                        className="tckt-tmpt-btns-save1"
                                        onClick={(e) => ChangeEngeeringDescription(e, engineeringDetails.engineering_details.value,
                                          editEngineeringDetails.engineering, engineeringDetails.engineering_details, 'engineering')}
                                      >
                                        Save
                                      </button>
                                    ) : ''}

                                    <button
                                      className="tckt-tmpt-btns-cnl"
                                      onClick={(e) => handleEngineeringCancel(e)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}

                              </div>

                            }




                          </div>
                        )}
                      </div>
                    )}

                    {engineeringDetails.production_details && (
                      <div
                        className="eng-detail-cont"
                        // style={{ background: '#F6F6F6', borderRadius: '4px', padding: '12px', width: '92%' }}
                        onClick={() => toggleDetails('production')}
                      >
                        <div className="ticket-view-ec">
                          <span>{engineeringDetails.production_details.fieldName}</span>
                          <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" />
                        </div>
                        {showDetails.production && (
                          // <div className="engineering-details-change" style={{ background: '#F6F6F6', borderRadius: '4px', padding: '12px' }}>
                          //   {!editable && !productionEditing ? (
                          //     <span className="ticket-view-span">{engineeringDetails.production_details.value}</span>
                          //   ) : (
                          //     <textarea
                          //       value={engineeringDetails.production_details.value}
                          //       className="engineering-editing-text"
                          //       onChange={(e) => handleEngineeringChange(e, 'production')}
                          //       onClick={(e) => e.stopPropagation()} // Prevent click propagation
                          //     />
                          //   )}
                          //   {(editable || productionEditing) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }} />}
                          // </div>
                          <div className="engineering-details-change" >
                            {(!engineeringEditing.production) &&
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <span className="ticket-view-span">{engineeringDetails.production_details.value}</span>
                                {(editable) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                  style={{ width: '15px', height: '15px' }} onClick={(e) => handleEditingEngineering(e, 'production')} className="editing-engineering" />}
                              </div>

                            }
                            {(engineeringEditing.production) &&
                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <textarea
                                  value={editEngineeringDetails.production ? editEngineeringDetails.production : engineeringDetails.production_details.value}
                                  className="engineering-editing-text"
                                  onChange={(e) => handleEngineeringChange(e, 'production')}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                {engineeringEditing.production && (
                                  <div className="tckt-tmpt-btns">
                                    {editEngineeringDetails.production ? (
                                      <button
                                        className="tckt-tmpt-btns-save1"
                                        onClick={(e) => ChangeEngeeringDescription(e, engineeringDetails.production_details.value,
                                          editEngineeringDetails.production, engineeringDetails.production_details, 'production')}
                                      >
                                        Save
                                      </button>
                                    ) : ''}

                                    <button
                                      className="tckt-tmpt-btns-cnl"
                                      onClick={(e) => handleEngineeringCancel(e)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}

                              </div>

                            }




                          </div>


                        )}
                      </div>
                    )}

                    {engineeringDetails.service_details && (
                      <div
                        className="eng-detail-cont"
                        // style={{ background: '#F6F6F6', borderRadius: '4px', padding: '12px', width: '92%' }}
                        onClick={() => toggleDetails('service')}
                      >
                        <div className="ticket-view-ec">
                          <span>{engineeringDetails.service_details.fieldName}</span>
                          <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" />
                        </div>
                        {showDetails.service && (
                          // <div className="engineering-details-change" style={{ background: '#F6F6F6', borderRadius: '4px', padding: '12px' }}>
                          //   {!editable && !serviceEditing ? (
                          //     <span className="ticket-view-span">{engineeringDetails.service_details.value}</span>
                          //   ) : (
                          //     <textarea
                          //       value={engineeringDetails.service_details.value}
                          //       className="engineering-editing-text"
                          //       onChange={(e) => handleEngineeringChange(e, 'service')}
                          //       onClick={(e) => e.stopPropagation()} // Prevent click propagation
                          //     />
                          //   )}
                          //   {(editable || serviceEditing) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }} />}
                          // </div>
                          <div className="engineering-details-change" >
                            {(!engineeringEditing.service) &&
                              <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                <span className="ticket-view-span">{engineeringDetails.service_details.value}</span>
                                {(editable) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                  style={{ width: '15px', height: '15px' }} onClick={(e) => handleEditingEngineering(e, 'service')} className="editing-engineering" />}
                              </div>

                            }
                            {(engineeringEditing.service) &&
                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <textarea
                                  value={editEngineeringDetails.service ? editEngineeringDetails.service : engineeringDetails.service_details.value}
                                  className="engineering-editing-text"
                                  onChange={(e) => handleEngineeringChange(e, 'service')}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                {engineeringEditing.service && (
                                  <div className="tckt-tmpt-btns">
                                    {editEngineeringDetails.service ? (
                                      <button
                                        className="tckt-tmpt-btns-save1"
                                        onClick={(e) => ChangeEngeeringDescription(e, engineeringDetails.service_details.value,
                                          editEngineeringDetails.service, engineeringDetails.service_details, 'service')}
                                      >
                                        Save
                                      </button>
                                    ) : ''}

                                    <button
                                      className="tckt-tmpt-btns-cnl"
                                      onClick={(e) => handleEngineeringCancel(e)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}

                              </div>

                            }




                          </div>
                        )}
                      </div>
                    )}
                  </>

                }
                {type !== 'pci-view' && <div>
                  <span>Attachments</span>
                  <div>
                    <input
                      type="file"
                      onChange={(e) => handleFileInputChange(e)}
                      className="full-width-input"
                      ref={inputRef}
                      disabled={!editable}
                      style={{ display: "none" }}
                    />
                    <div
                      className="filedropzone"
                      onDrop={handleFileDrop}
                      onDragOver={handleDragOver}
                      style={{ width: '90%' }}
                    >
                      <img
                        src={`${ASSET_PREFIX_URL}uploading-icon.svg`}
                        alt=""
                      />
                      {isLoading ? (
                        <p>Uploading ....</p>
                      ) : (
                        <p>
                          Drop files to attach or&nbsp;
                          <span onClick={() => inputRef.current.click()}>
                            Browse
                          </span>
                        </p>
                      )}
                    </div>
                    <div className="file-info">
                      {fileFormat && <p>File Format: {fileFormat}</p>}

                    </div>
                    <div className="uploaded-files-view">
                      {attachedFiles.filter(file => file.action !== 'remove').map((file, index) => (
                        <div key={index} className="uploaded-file-view">
                          <div className="file-details-view" >
                            <div className="file-image-preview">
                              {getFileIcon(file.key)}
                            </div>

                            {/* <img src={PHOTO_LINK + file.key} alt="" width="75px"
                              height="75px" /> */}
                            {/* {file.type === 'png' ? <img src={PHOTO_LINK + file.key} alt="" width="75px"
                              height="75px" /> : <iframe
                              src={PHOTO_LINK + file.key}
                              width="100%"
                              height="100%"
                              style={{ overflow: 'hidden', border: 'none', transform: 'scale(1)', transformOrigin: '0 0' }} // Adjust scale as needed
                              scrolling="no"
                            />} */}



                            {/* <img src={PHOTO_LINK+file.key} width='80px'/> */}
                            <div className="upld-file-details-view">


                              <span title={file.name} className="file-view-name">{file.name.length > 20 ? file.name.substring(0, 15) + '...' : file.name}</span>




                            </div>

                            <div className="file-details-view-hover">
                              <a href={PHOTO_LINK + file.key} target="_blank" rel="noreferrer">
                                <div className="file-box-link">
                                  <span title={file.name} className="file-view-name-view">{file.name.length > 20 ? file.name.substring(0, 18) + '...' : file.name}</span>

                                </div>
                              </a>
                              {editable ? <>
                                <div className="file-delete">
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() => openChangeLogsPopUP(file)} />
                                </div>

                              </> : ''}
                            </div>

                          </div>




                        </div>
                      ))}

                    </div>
                  </div>
                </div>}

                {type === 'template' ? <div><br />
                  <span>{comentLabel}</span>
                  <div className="comments-bottom-cont" style={{ width: '100%' }}>

                    <NameProfile userName={memberPhoto.fullName} memberPhoto={memberPhoto.photo} width="36px" fontweight='500' />


                    <textarea
                      placeholder="Add a comment.."
                      className="comments-textarea"
                      onClick={handleTextareaClick}
                      onChange={(e) => setComment(e.target.value)}
                      value={comments}
                      disabled={!editableComment}
                    />
                  </div>
                  {showButtons && (
                    <div className="tckt-tmpt-btns">
                      {comments === "" ? (
                        <button
                          className="tckt-tmpt-btns-save"
                          onClick={saveComments}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="tckt-tmpt-btns-save1"
                          onClick={saveComments}
                        >
                          Save
                        </button>
                      )}

                      <button
                        className="tckt-tmpt-btns-cnl"
                        onClick={handleCommentsCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div> : ''}

                <div className="get-comments-box">
                  {showComment.map((comment, index) => (
                    <div
                      key={index}
                      style={{ width: '92%' }}
                      id={`comment-${index}`}
                      onMouseEnter={() => handleMouseEnter(index, comment, comment.updatedAt)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      className="comment-details-cont"
                    >
                      <div className="commented-member-image">
                        <NameProfile userName={comment.memberName} memberPhoto={comment.memberPhoto} width="36px" fontweight='500' />
                      </div>
                      <div className="commented-details">
                        <div className="commented-member-details">
                          <div>
                            <span className="commenter-name">
                              {comment.memberName}
                            </span> &nbsp;
                            <span className="commented-time">
                              {new Date(comment.updatedAt).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </span>
                          </div>
                          {(showEditButtons[index]) && (
                            <div className="commented-edit-delete-btns">
                              <img
                                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                alt=""
                                onClick={() => handleEditIconClick(index)}
                                style={{
                                  cursor: "pointer",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                              &nbsp;&nbsp;&nbsp;
                              {/* <EditOutlinedIcon onClick={editComments} style={{cursor:'pointer', width:'15px' ,height:'15px'}}/> */}
                              <img
                                src={`${ASSET_PREFIX_URL}delete_comment.png`}
                                alt=""
                                onClick={() => deleteComment(comment._id)}
                                style={{
                                  cursor: "pointer",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                              {/* <CloseIcon  onClick={() =>deleteComment(comment._id)} style={{cursor:'pointer',width:'15px' ,height:'15px'}}/>         */}
                            </div>
                          )}
                        </div>
                        {/* <textarea value={comment.comments} className="text-area-type"/> */}
                        {editingIndex === index ? (
                          <>
                            <textarea
                              value={comment.comments}
                              onChange={(e) => handleEditChange(e, index)}
                              className="text-area-type"
                            // onClick={handleSaveEditButtons}
                            />
                            {showEditSaveButtons && (
                              <div className="tckt-tmpt-btns">
                                <button
                                  className="tckt-tmpt-btns-save"
                                  onClick={() =>
                                    editComments(comment._id, comment.comments)
                                  }
                                >
                                  Save
                                </button>
                                <button
                                  className="tckt-tmpt-btns-cnl"
                                  onClick={handleCancelEdit}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <span className="text-area-type">
                            {comment.comments}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {(selectedEcType === 'structuring_ec' || type === 'pci-view') && (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="setting-navs" style={{ marginBottom: "20px" }}>
                      <div
                        className={`privacys ${selectedViewType === "excel" ? "settingActive" : ""}`}
                        onClick={() => handleSectionViewClick("excel")}
                        style={{
                          cursor: "pointer",
                          boxShadow: selectedViewType === "excel" ? "0px 3px 0px 0px #FF7A7A" : "none",
                          padding: "10px 16px",
                          color: selectedViewType === "excel" ? "#FF7A7A" : "black",
                        }}
                      >
                        Excel view
                      </div>

                      <div
                        className={`generals ${selectedViewType === "tree" ? "settingActive" : ""}`}
                        onClick={() => handleSectionViewClick("tree")}
                        style={{
                          cursor: "pointer",
                          boxShadow: selectedViewType === "tree" ? "0px 3px 0px 0px #FF7A7A" : "none",
                          padding: "10px 16px",
                          color: selectedViewType === "tree" ? "#FF7A7A" : "black",
                        }}
                      >
                        Tree view
                      </div>
                    </div>
                    {editable && <button className="tckt-tmpt-btns-save" style={{ height: '40px', borderRadius: '8px' }}
                      onClick={handleModifyBom}>
                      Modify BOM
                    </button>}
                  </div>

                )}


                {selectedViewType === 'tree' && (
                  <div className="part-bom-view" >
                    <BomHierarchy sequence_id={sequence_id} selectedSection={selectedViewType} bomType={type} />
                  </div>
                )}
                {selectedViewType === 'excel' && (
                  <div className="part-excell-view">
                    <ExcelView sequence_id={sequence_id} selectedSection={selectedViewType} tableView={type} />
                  </div>
                )}



              </div>
              <div className="ticket-view-bottm-left">
                {(type !== 'template') &&
                  <div className='setting-navs'>
                    {type !== 'pci-view' && <div className={`generals${selectedSection === 'details' ? 'settingActive' : ''}`}
                      onClick={() => handleSectionClick('details')}
                      style={{

                        cursor: 'pointer', boxShadow: selectedSection === 'details' ? '0px 3px 0px 0px #610bee' : 'none',
                        padding: type === 'ec-template' ? "10px 16px" : "10px", color: selectedSection === 'details' ? '#610bee' : "black"
                      }}>Details</div>}


                    <div
                      title="trial plan approval list"
                      className={`user${selectedSection === 'approval' ? 'settingActive' : ''}`}
                      onClick={() => handleSectionClick('approval')}
                      style={{
                        // width: '120px',
                        cursor: 'pointer',
                        boxShadow: selectedSection === 'approval' ? '0px 3px 0px 0px #610bee' : 'none',
                        padding: type === 'ec-template' ? '10px 16px' : '10px',
                        color: (selectedSection === 'approval') ? '#610bee' : 'black'
                      }}
                    >
                      Approvers {type !== 'pc-template' && `(${approvalCount})`}
                    </div>




                    <div className={`privacys${selectedSection === 'parts' ? 'settingActive' : ''}`}
                      onClick={() => handleSectionClick('parts')}
                      style={{
                        // width: '80px',
                        cursor: 'pointer',
                        boxShadow: selectedSection === 'parts' ? '0px 3px 0px 0px #610bee' : 'none',
                        padding: type === 'ec-template' ? "10px 16px" : "10px",
                        color: selectedSection === 'parts' ? '#610bee' : "black"
                      }}>Parts (<PartNumberCountFormat count={partsCount} />)
                    </div>
                    {(type === 'ec-deviation' || type === 'pc-template') && <div className={`privacys${selectedSection === 'ec-list' ? 'settingActive' : ''}`}
                      onClick={() => handleSectionClick('ec-list')}
                      style={{

                        cursor: 'pointer',
                        boxShadow: selectedSection === 'ec-list' ? '0px 3px 0px 0px #610bee' : 'none',
                        padding: type === 'ec-template' ? "10px 16px" : "10px",
                        color: selectedSection === 'ec-list' ? '#610bee' : "black"
                      }}>EC list({ecsList.length})</div>}



                  </div>}

                {selectedSection === 'details' &&
                  <>
                    <div className="defaultDetails">

                      {Object.keys(defaultDetails).map((key) => {
                        const detail = defaultDetails[key];

                        if (detail.fieldName === "Comments" || detail.fieldName === 'Select part numbers' ||
                          (type === 'ec-template' && (detail.fieldName === 'Deviation end date' || detail.fieldName === 'Select EC')) ||
                          (type === 'ec-deviation' && (detail.fieldName === 'Select EC' || detail.fieldName === 'EC Title')) ||
                          (type === 'pc-template' && (detail.fieldName === 'Select EC'))) {
                          // Skip rendering space for the "Comments" input type or when `type` is 'ec-template' and `detail.fieldName` is 'end Date' or 'select ec'
                          return null;
                        }


                        return (
                          <div key={key}>
                            {defaultDetails[key].fieldName === "Owner" && (
                              <>
                                <div className="security-select">
                                  <span className="security-select-span">
                                    {defaultDetails[key].fieldName}
                                  </span>

                                  <Select
                                    isDisabled
                                    value={{
                                      label: (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          {defaultDetails[key].value.photo ? <img
                                            src={PHOTO_LINK + defaultDetails[key].value.photo}
                                            alt="options"
                                            className="owner-image"
                                          /> : <div className="owner-image-span">
                                            {defaultDetails[key].value.fullName && defaultDetails[key].value.fullName.includes(' ')
                                              ? defaultDetails[key].value.fullName.charAt(0).toUpperCase() + defaultDetails[key].value.fullName.charAt(defaultDetails[key].value.fullName.indexOf(' ') + 1).toUpperCase()
                                              : defaultDetails[key].value && defaultDetails[key].value.fullName
                                                ? defaultDetails[key].value.fullName.charAt(0).toUpperCase()
                                                : ''}
                                          </div>}


                                          {defaultDetails[key].value.fullName.length < 15 ?
                                            defaultDetails[key].value.fullName : defaultDetails[key].value.fullName.slice(0, 15) + '...'}
                                        </div>
                                      ),
                                    }}
                                    styles={customStyles}
                                    className="security-select-tag"
                                  />
                                </div>
                              </>
                            )}
                            {(defaultDetails[key].inputType === "select-person" || defaultDetails[key].fieldName === 'select_ec') && (
                              null
                            )}
                            {defaultDetails[key].inputType === "person" &&
                              defaultDetails[key].fieldName !== "Owner" && (
                                <>
                                  <div className="security-select">
                                    <span className="security-select-span">
                                      {defaultDetails[key].fieldName}
                                    </span>
                                    {defaultDetails[key].fieldName ===
                                      "Watchers" ? (
                                      <Select
                                        isDisabled={!editable}
                                        // isDisabled={!editableComment}
                                        // isDisabled
                                        isMulti
                                        value={defaultDetails[key].value.map(
                                          (option) => ({
                                            value: (
                                              <div
                                                style={customStyles.optionLabel}
                                                className="option-label"
                                              >

                                                <img
                                                  src={option.photo ? PHOTO_LINK + option.photo : DEFAULT_PHOTO}
                                                  alt="options"
                                                  className="owner-image"
                                                />

                                                {option.fullName}
                                                <span style={{ display: 'none' }}>{option.member_id}</span>
                                              </div>
                                            ),
                                            label: (
                                              <div
                                                style={customStyles.optionLabel}
                                                className="option-label"
                                              >
                                                {option.photo ? <img
                                                  src={PHOTO_LINK + option.photo}
                                                  alt="options"
                                                  className="owner-image"
                                                /> : <div className="owner-image-span">
                                                  <NameProfile userName={option.fullName} />

                                                </div>}


                                                {option.fullName.length < 15 ? option.fullName : option.fullName.slice(0, 12) + '...'}

                                                <span style={{ display: 'none' }}>{option.member_id}</span>
                                              </div>
                                            ),
                                          })
                                        )} // Assuming you have a selected value for watchers
                                        options={watchersDetails.map((member) => ({
                                          value: {
                                            photo: member.photo,
                                            member_id: member._id,
                                            fullName: member.fullName,
                                          }, // Use full name as the value
                                          label: (
                                            <div
                                              className="member-option"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              {member.photo ? <img
                                                src={
                                                  member.photo
                                                    ? PHOTO_LINK + member.photo
                                                    : DEFAULT_PHOTO
                                                }
                                                alt=""
                                                style={{
                                                  borderRadius: "50%",
                                                  width: "26px",
                                                  height: "26px",
                                                }}
                                              /> : <div className="owner-image-span">
                                                <NameProfile userName={member.fullName} />
                                              </div>}

                                              {member.fullName}
                                              <span style={{ display: 'none' }}>{member._id}</span>
                                            </div>
                                          ),
                                        }))}
                                        styles={customStyles}
                                        className="security-select-tag"

                                        onChange={(selectedOption) =>
                                          watchersChange(key, selectedOption)
                                        }

                                      />
                                    ) : (
                                      <Select
                                        isDisabled={!editable}
                                        value={{
                                          label: (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >

                                              {defaultDetails[key].value.photo ? <img
                                                src={PHOTO_LINK + defaultDetails[key].value.photo}
                                                alt="options"
                                                className="owner-image"
                                              /> : <div className="owner-image-span">
                                                <NameProfile userName={defaultDetails[key].value.fullName} />
                                              </div>}

                                              {defaultDetails[key].value.fullName.length < 15 ? defaultDetails[key].value.fullName : defaultDetails[key].value.fullName.slice(0, 15) + '...'}
                                              {/* <span>{defaultDetails[key].value.member_id}</span> */}
                                            </div>
                                          ),
                                        }}
                                        options={memberDetails.map((option) => ({
                                          value: option._id,
                                          label: (
                                            <div style={customStyles.optionLabel}>
                                              {option.photo ? <img
                                                src={PHOTO_LINK + option.photo}
                                                alt="options"
                                                className="owner-image"
                                              /> : <div className="owner-image-span">
                                                <NameProfile userName={option.fullName} />
                                              </div>}

                                              {/* &nbsp;&nbsp; */}
                                              {option.fullName}
                                            </div>
                                          ),
                                        }))}
                                        styles={customStyles}
                                        onChange={(selectedOption) =>
                                          handleChange(key, selectedOption)
                                        }
                                        className="security-select-tag"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            {defaultDetails[key].inputType === "single_select" && (
                              <>
                                <div className="security-select">
                                  <span className="security-select-span">
                                    {defaultDetails[key].fieldName}
                                  </span>
                                  {defaultDetails[key].fieldName === "Priority" ? (
                                    <Select
                                      isDisabled={!editable}
                                      value={{
                                        value: (
                                          <div
                                            className="priority-icon"
                                            style={{
                                              background: getOptionColor(priority),
                                            }}
                                          >
                                            {defaultDetails[key].value}
                                          </div>
                                        ),
                                        label: (
                                          <div
                                            style={{
                                              background: getOptionColor(priority),
                                            }}
                                            className="priority-icon"
                                          >
                                            {defaultDetails[key].value}
                                          </div>
                                        ),
                                      }}
                                      options={defaultDetails[key].option.map(
                                        (option) => ({
                                          value: (
                                            <div
                                              style={{ background: "orange" }}
                                              className="security-icon"
                                            >
                                              {option}
                                            </div>
                                          ),
                                          label: (
                                            <div
                                              style={{ background: "orange" }}
                                              className="priority-icon"
                                            >
                                              {option}
                                            </div>
                                          ),
                                        })
                                      )}
                                      className="security-select-tag"
                                      styles={customStyles}
                                      onChange={(selectedOption) =>
                                        handleChange(key, selectedOption)
                                      }
                                    />
                                  ) : (
                                    <Select
                                      isDisabled={!editable}
                                      value={{
                                        value: (
                                          <div
                                            className="priority-icon"
                                            style={{ background: "orange" }}
                                          ></div>
                                        ),
                                        label: (
                                          <div
                                            style={{ background: "orange" }}
                                            className="priority-icon"
                                          >
                                            {defaultDetails[key].value}
                                          </div>
                                        ),
                                      }}
                                      options={defaultDetails[key].option.map(
                                        (option) => ({
                                          value: (
                                            <div
                                              style={{ background: "orange" }}
                                              className="security-icon"
                                            >
                                              {option}
                                            </div>
                                          ),
                                          label: (
                                            <div
                                              style={{ background: "orange" }}
                                              className="priority-icon"
                                            >
                                              {option}
                                            </div>
                                          ),
                                        })
                                      )}
                                      className="security-select-tag"
                                      styles={customStyles}
                                      onChange={(selectedOption) =>
                                        handleChange(key, selectedOption)
                                      }
                                    />
                                  )}
                                </div>
                              </>
                            )}
                            {(defaultDetails[key].inputType === "multi_select") && (
                              <div className="custom-details-div">
                                <span className="custom-label-span">
                                  {defaultDetails[key].fieldName}:
                                </span>
                                <Select
                                  isDisabled={!editable}
                                  isMulti
                                  // options={defaultDetails[key].fieldName === "Select EC" ?
                                  //   ecTitles.map(title => ({ value: title, label: title })) : defaultDetails[key].options.map(opt => ({ value: opt, label: opt }))}
                                  options={
                                    defaultDetails[key].fieldName === "Select EC"
                                      ? ecTitles.map(title => ({ value: title.ec_id, label: title.ec_title }))
                                      : defaultDetails[key].options.map(opt => ({ value: opt, label: opt }))
                                  }
                                  value={
                                    defaultDetails[key].fieldName === "Select EC"
                                      ? defaultDetails[key].value.map(title => ({ value: title.ec_id, label: title.ec_title }))
                                      : defaultDetails[key].value.map(opt => ({ value: opt, label: opt }))

                                  }
                                  className="security-select-tag"
                                  onChange={(selectedOptions) =>
                                    handleDefaultMultiSelectChange(
                                      key,
                                      selectedOptions
                                    )
                                  }
                                />
                              </div>
                            )}
                            {/* {defaultDetails[key] === 'created_on' && (
                          <>
                            <div className="security-select">
                              <span className="security-select-span">{defaultDetails[key].fieldName}</span>
                              <input type="date" disabled className="security-select-date" value={defaultDetails[key].value} />
                            </div>
                          </>
                        )} */}
                            {defaultDetails[key].inputType === "date" && (
                              <>
                                <div className="security-select">
                                  <span className="security-select-span">
                                    {defaultDetails[key].fieldName}
                                  </span>
                                  <input
                                    type="date"
                                    disabled={
                                      !editable ||
                                      defaultDetails[key] === "created_on"
                                    }
                                    min={new Date().toISOString().split("T")[0]}
                                    className="security-select-date"
                                    height="40px"
                                    value={defaultDetails[key].value}
                                    onChange={(event) => handleChange(key, event)}
                                  />
                                </div>
                              </>
                            )}

                            {defaultDetails[key].inputType === "date_range" && (
                              <>
                                <div className="security-select">
                                  <span className="security-select-span">
                                    {defaultDetails[key].fieldName}:
                                  </span>
                                  <div className="custom-date-range" style={{ border: '1px solid #EDF2F7', padding: '5px' }}>
                                    <div >
                                      <span>Start Date:</span><br />
                                      <input
                                        type="date"
                                        className="dates_ranges-input"
                                        value={defaultDetails[key].value.start}
                                        disabled={!editable}
                                        min={new Date().toISOString().split("T")[0]}
                                        onChange={(event) =>
                                          handleDateRangeChange(key, 'start', event.target.value)
                                        }
                                      />
                                    </div>
                                    <div >
                                      <span>End Date:</span><br />
                                      <input
                                        type="date"
                                        className="dates_ranges-input"
                                        value={defaultDetails[key].value.end}
                                        disabled={!editable}
                                        min={defaultDetails[key].value.start}
                                        onChange={(event) =>
                                          handleDateRangeChange(key, 'end', event.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                </div>
                              </>
                            )}


                          </div>
                        );
                      })}
                    </div>

                    <div className="customdetails">
                      {ticketDetails ? (
                        <>
                          {Object.keys(ticketDetails).map((key) => (
                            <div key={key}>
                              {ticketDetails[key].value === "" ||
                                ticketDetails[key].value === undefined ||
                                ticketDetails[key].value.length < 1 ||
                                ticketDetails === undefined ? (
                                <div></div>
                              ) : (
                                <div>
                                  {(ticketDetails[key].inputType === "text" ||
                                    ticketDetails[key].inputType === "team") && (
                                      <>
                                        <div className="custom-details-div">
                                          <span className="custom-label-span">
                                            {ticketDetails[key].fieldName}:
                                          </span>
                                          <input
                                            disabled={!editable}
                                            type="text"
                                            value={
                                              customEditing
                                                ? textEditable[key]
                                                : ticketDetails[key].value
                                            }
                                            onChange={(event) =>
                                              setCustomEditing(event.target.value)
                                            }
                                            onClick={() => toggleEditable(key)}
                                          // onChange={event => handleInputChange(key, event)}
                                          />
                                        </div>
                                        {selectedInputKey === key && (
                                          <div className="tckt-tmpt-btns">
                                            {customEditing ? (
                                              <>
                                                <button
                                                  className="tckt-tmpt-btns-save1"
                                                  onClick={() => handleSave(key)}
                                                >
                                                  Save
                                                </button>
                                                <button
                                                  className="tckt-tmpt-btns-cnl"
                                                  onClick={() => handleCancel(key)}
                                                >
                                                  Cancel
                                                </button>
                                              </>
                                            ) : (
                                              <button
                                                className="tckt-tmpt-btns-cnl"
                                                onClick={() => handleCancel(key)}
                                              >
                                                Cancel
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  {ticketDetails[key].inputType === "link" && (
                                    <>
                                      <div className="security-select">
                                        <span className="security-select-span">
                                          {ticketDetails[key].fieldName}
                                        </span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }} className="custom-label-innerdiv">
                                          {!linkInput[key] && (
                                            <>
                                              <a href={ticketDetails[key].value.includes('http') ? ticketDetails[key].value : `https://${ticketDetails[key].value}`} target="_blank" className="form-link">
                                                {ticketDetails[key].value}
                                              </a>
                                              {editable && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} width='15px' height='15px' onClick={() => toggleLinkEditable(key)} />}
                                            </>
                                          )}
                                          {linkInput[key] && (
                                            <input
                                              value={customEditing ? textEditable[key] : ticketDetails[key].value}
                                              onChange={(event) => setCustomEditing(event.target.value)}
                                            />
                                          )}
                                        </div>
                                        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }} className="custom-label-innerdiv"><a href={ticketDetails[key].value} target="_blank" className="form-link" >{ticketDetails[key].value}</a>
                                      <img src={${ASSET_PREFIX_URL}edit-ticket.png} width='15px' height='15px' onClick={() => toggleEditable(key)} />
                                    </div> */}

                                      </div>
                                      {selectedInputKey === key && (
                                        <div className="tckt-tmpt-btns">
                                          {customEditing ? (
                                            <>
                                              <button
                                                className="tckt-tmpt-btns-save1"
                                                onClick={() => handleSave(key)}
                                              >
                                                Save
                                              </button>
                                              <button
                                                className="tckt-tmpt-btns-cnl"
                                                onClick={() => handleLinkCancel(key)}
                                              >
                                                Cancel
                                              </button>
                                            </>
                                          ) : (
                                            <button
                                              className="tckt-tmpt-btns-cnl"
                                              onClick={() => handleLinkCancel(key)}
                                            >
                                              Cancel
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {ticketDetails[key].inputType === "person" && (
                                    <div className="security-select">
                                      <span className="security-select-span">
                                        {ticketDetails[key].fieldName}:
                                      </span>
                                      <Select
                                        isDisabled={!editable}
                                        value={{
                                          label: (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >

                                              {ticketDetails[key].value.photo ? <img
                                                src={PHOTO_LINK + ticketDetails[key].value.photo}
                                                alt="options"
                                                className="owner-image"
                                              /> : <div className="owner-image-span">
                                                <NameProfile userName={ticketDetails[key].value.fullName} />
                                              </div>}

                                              {ticketDetails[key].value.fullName.length < 15 ? ticketDetails[key].value.fullName : ticketDetails[key].value.fullName.slice(0, 15) + '...'}
                                            </div>
                                          ),
                                        }}
                                        options={memberDetails.map((option) => ({
                                          value: option._id,
                                          label: (
                                            <div style={customStyles.optionLabel}>

                                              {option.photo ? <img
                                                src={PHOTO_LINK + option.photo}
                                                alt="options"
                                                className="owner-image"
                                              /> : <div className="owner-image-span">
                                                <NameProfile userName={option.fullName} />
                                              </div>}

                                              {option.fullName}
                                            </div>
                                          ),
                                        }))}
                                        styles={customStyles}
                                        onChange={(selectedOption) =>
                                          handleCustomChange(key, selectedOption)
                                        }
                                        className="security-select-tag"
                                      />
                                    </div>
                                  )}

                                  {ticketDetails[key].inputType === "date" && (
                                    <div className="custom-details-div">
                                      <span className="custom-label-span">
                                        {ticketDetails[key].fieldName}:
                                      </span>
                                      <input
                                        disabled={!editable}
                                        type="date"
                                        value={ticketDetails[key].value}
                                        min={new Date().toISOString().split("T")[0]}
                                        onChange={(event) =>
                                          handleCustomChange(key, event)
                                        }
                                      />
                                    </div>
                                  )}
                                  {ticketDetails[key].inputType === "number" && (
                                    <>
                                      <div className="custom-details-div">
                                        <span className="custom-label-span">
                                          {ticketDetails[key].fieldName}:
                                        </span>
                                        <input
                                          disabled={!editable}
                                          type="number"
                                          value={
                                            customEditing
                                              ? textEditable[key]
                                              : ticketDetails[key].value
                                          }
                                          onChange={(event) =>
                                            setCustomEditing(event.target.value)
                                          }
                                          onClick={() => toggleEditable(key)}
                                        />
                                      </div>
                                      {selectedInputKey === key && (
                                        <div className="tckt-tmpt-btns">
                                          {customEditing ? (
                                            <>
                                              <button
                                                className="tckt-tmpt-btns-save1"
                                                onClick={() => handleSave(key)}
                                              >
                                                Save
                                              </button>
                                              <button
                                                className="tckt-tmpt-btns-cnl"
                                                onClick={() => handleCancel(key)}
                                              >
                                                Cancel
                                              </button>
                                            </>
                                          ) : (
                                            <button
                                              className="tckt-tmpt-btns-cnl"
                                              onClick={() => handleCancel(key)}
                                            >
                                              Cancel
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {ticketDetails[key].inputType ===
                                    "date_range" && (
                                      <div className="custom-details-div">
                                        <span className="custom-label-span">
                                          {ticketDetails[key].fieldName}:
                                        </span>
                                        <div className="custom-date-range" style={{ border: '1px solid #EDF2F7', padding: '5px' }}>
                                          <div >
                                            <span>Start Date:</span><br />
                                            <input
                                              type="date"
                                              value={ticketDetails[key].value.start}
                                              disabled={!editable}
                                              min={new Date().toISOString().split("T")[0]}
                                              onChange={(event) =>
                                                handleCustomDateRangeChange(key, 'end', event.target.value)
                                              }
                                            />
                                          </div>
                                          <div >
                                            <span>End Date:</span><br />
                                            <input
                                              type="date"
                                              value={ticketDetails[key].value.end}
                                              disabled={!editable}
                                              min={ticketDetails[key].value.start}
                                              onChange={(event) =>
                                                handleCustomDateRangeChange(key, 'end', event.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* <RangePicker
                                     value={{
                                      start: ticketDetails[key].value.start,
                                      end: ticketDetails[key].value.end,
                                    }}
                                    // onChange={handleDateChange}
                                    style={{ width: 240 }}
                                    allowClear={false}
                                    disabledDate={!editable}
                                  /> */}
                                      </div>
                                    )}
                                  {ticketDetails[key].inputType ===
                                    "multi_select" && (
                                      <div className="custom-details-div">
                                        <span className="custom-label-span">
                                          {ticketDetails[key].fieldName}:
                                        </span>
                                        <Select
                                          isDisabled={!editable}
                                          isMulti
                                          options={ticketDetails[key].option.map(
                                            (val) => ({
                                              value: val,
                                              label: val,
                                            })
                                          )}
                                          value={ticketDetails[key].value.map(
                                            (val) => ({
                                              value: val,
                                              label: val,
                                            })
                                          )}
                                          className="security-select-tag"
                                          onChange={(selectedOptions) =>
                                            handleMultiSelectChange(
                                              key,
                                              selectedOptions
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  {ticketDetails[key].inputType === "paragraph" && (
                                    <>
                                      <div className="custom-details-div">
                                        <span className="custom-label-span">
                                          {ticketDetails[key].fieldName}:
                                        </span>
                                        <textarea
                                          disabled={!editable}
                                          placeholder="description"
                                          value={
                                            customEditing
                                              ? textEditable[key]
                                              : ticketDetails[key].value
                                          }
                                          onChange={(event) =>
                                            setCustomEditing(event.target.value)
                                          }
                                          onClick={() => toggleEditable(key)}
                                        />
                                      </div>
                                      {selectedInputKey === key && (
                                        <div className="tckt-tmpt-btns">
                                          {customEditing ? (
                                            <>
                                              <button
                                                className="tckt-tmpt-btns-save1"
                                                onClick={() => handleSave(key)}
                                              >
                                                Save
                                              </button>
                                              <button
                                                className="tckt-tmpt-btns-cnl"
                                                onClick={() => handleCancel(key)}
                                              >
                                                Cancel
                                              </button>
                                            </>
                                          ) : (
                                            <button
                                              className="tckt-tmpt-btns-cnl"
                                              onClick={() => handleCancel(key)}
                                            >
                                              Cancel
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {ticketDetails[key].inputType ===
                                    "single_select" && (
                                      <div className="custom-details-div">
                                        <span className="custom-label-span">
                                          {ticketDetails[key].fieldName}:
                                        </span>
                                        <Select
                                          isDisabled={!editable}
                                          options={ticketDetails[key].option.map(
                                            (opt) => ({ value: opt, label: opt })
                                          )}
                                          value={
                                            ticketDetails[key].value
                                              ? {
                                                value: ticketDetails[key].value,
                                                label: ticketDetails[key].value,
                                              }
                                              : null
                                          }
                                          className="security-select-tag"
                                          onChange={(selectedOption) =>
                                            handleCustomChange(key, selectedOption.value)
                                          }
                                        />
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>}

                {(selectedSection === 'approval') && (
                  type !== 'pc-template' ? (
                    <ApproversComponent
                      editable={editable}
                      searcingApprovals={searcingApprovals}
                      handleSeachApprovals={handleSeachApprovals}
                      approvalLoading={approvalLoading}
                      approvalListOfMembers={approvalListOfMembers}
                      searchCondition={searchCondition}
                      selectedMembers={selectedMembers}
                      handleAddToApprovalList={handleAddToApprovalList}
                      ecApprovalStatus={ecApprovalStatus}
                    />
                  ) : (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {type === 'pc-template' && (
                          <div
                            title="trial plan approval list"
                            className={`user${selectedApproverSection === 'test-approval' ? 'settingActive' : ''}`}
                            onClick={() => handleApprovalSectionClick('test-approval')}
                            style={{
                              width: '50%',
                              cursor: 'pointer',
                              boxShadow: selectedApproverSection === 'test-approval' ? '0px 3px 0px 0px #610bee' : 'none',
                              padding: type === 'ec-template' ? '10px 16px' : '10px',
                              color: selectedApproverSection === 'test-approval' ? '#610bee' : 'black'
                            }}
                          >
                            Test Phase({approvalCount?.test_phase_approvers?.value.length || 0})
                          </div>
                        )}
                        {type === 'pc-template' && (
                          <div
                            title="trial result approval list"
                            className={`user${selectedApproverSection === 'res_approval' ? 'settingActive' : ''}`}
                            onClick={() => handleApprovalSectionClick('res_approval')}
                            style={{
                              width: '50%',
                              cursor: 'pointer',
                              boxShadow: selectedApproverSection === 'res_approval' ? '0px 3px 0px 0px #610bee' : 'none',
                              padding: type === 'ec-template' ? '10px 16px' : '10px',
                              color: selectedApproverSection === 'res_approval' ? '#610bee' : 'black'
                            }}
                          >
                            Launch Phase ({approvalCount?.launch_phase_approvers?.value.length || 0})
                          </div>
                        )}
                      </div>
                      {(selectedApproverSection === 'res_approval' || selectedApproverSection === 'test-approval') && (
                        <ApproversComponent
                          editable={editable}
                          searcingApprovals={searcingApprovals}
                          handleSeachApprovals={handleSeachApprovals}
                          approvalLoading={approvalLoading}
                          approvalListOfMembers={approvalListOfMembers}
                          searchCondition={searchCondition}
                          selectedMembers={selectedMembers}
                          handleAddToApprovalList={handleAddToApprovalList}
                          ecApprovalStatus={ecApprovalStatus}
                        />
                      )}
                    </>

                  )
                )}




                {selectedSection === 'parts' &&

                  <EcpartsPage editable={((type === 'ec-template' && (selectedEcType === 'structuring_ec') || type === 'pci-view')) ? false : editable}

                    sequence_id={sequence_id} searchedParts={searchParts} type={type} ticketId={ticketId} />
                }
                {selectedSection === 'ec-list' &&

                  <DevEcList editable={editable} sequence_id={sequence_id} type={type} ticketId={ticketId} fetchFormDetails={fetchFormDetails} />
                }


              </div>
            </div>
          </div >
          {isPopupOpen && (
            <ChangeLogs onClose={handleClosePopup} ticketId={ticketId} type={type} />
          )
          }
          {
            changeDetails && (
              <ChangesPopUp
                onClose={handleCloseChange}
                ticketId={ticketId}
                type={type}
                prevData={previousData}
                newData={newData}
                saveBtn={showButtonsDescrip}
                key={key}
                responseData={responseData}
                onUpdateDetails={fetchFormDetails}
                attachmentDelete={attachmentDelete}
                deleteAttachment={deleteAttachment}
                attachmentKey={attachmentKey}
              />
            )
          }
          {
            (openApproveList === 'approvelist' || openApproveList === 'plan-approvers' || openApproveList === 'trial-approvers') && <EcApproveList
              type={type}
              openApproveList={type === 'pc-template' && openApproveList}
              onclose={handleOpenApproveListClose}
              ticketId={ticketId}
              prevObj={previousData}
              sequence_id={sequence_id} />
          }
          {openDelete && <DeleteTicketPopUp onClose={handleCloseDelete} />}
          {
            (openApproveList === 'reject' || openApproveList === 'reject-plan' ||
              openApproveList === 'reject-trail') && <RejectPopUp
              onclose={handleOpenApproveListClose}
              openApproveList={type === 'pc-template' && openApproveList}
              ticketId={ticketId}
              ticketName={ticketName}
              notifyIds={notifyIds}
              sequence_id={sequence_id} type={type} />
          }
          {openApprover && <ApprovePopUp
            onclose={() => setOpenApprover(!openApprover)}
            ticketId={ticketId}
            ticketName={ticketName}
            notifyIds={notifyIds}
            sequence_id={sequence_id} type={type} />}
          {openDelete && <DeleteTicketPopUp onClose={handleCloseDelete} />}

          {openPartsAdded && <PartsBeforePopUp
            handleclose={handleclosePartsAddPopUp}
            onclose={handleOpenApproveListClose}

            errorPopUpPlose={handlecloseErrorPopUp} errorParts={errorParts} ticketId={ticketId} sequence_id={sequence_id} type={type}
            selectedEcType={type === 'ec-template' && selectedEcType} />}
          {openErrorsParts && <ErrorPartsPopUp
            errorParts={errorParts} type={type}
            sequence_id={sequence_id} handleclose={handlecloseErrorPopUp} ticketId={ticketId} selectedEcType={type === 'ec-template' && selectedEcType} />}
        </>
      )
      }
    </>
  );
}

export default DetailsViewPage;