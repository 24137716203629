import React, { useState, useEffect, useCallback } from 'react';
import './ProductionSchedule.css';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { ASSET_PREFIX_URL, BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import Loading from '../Common/Loading/Loading';
import Pagination from '../Common/CommonJsx/Pagination';
import SearchFilter from './../Common/CommonJsx/SearchFilter';
// import { debounce } from './../../constants/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
const today = new Date();
const start = new Date(today);
start.setDate(today.getDate() - 3);
const end = new Date(start);
end.setMonth(start.getMonth() + 2);
const formatDate = (date) => date.toISOString().split('T')[0];

function MaterialPlanning() {
    const [visibleDates, setVisibleDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('daily');
    const [loading, setLoading] = useState(false);
    const [scheduleData, setScheduleData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [startDate, setStartDate] = useState(formatDate(start));
    const [endDate, setEndDate] = useState(formatDate(end));
    const [currentPartPage, setCurrentPartPage] = useState(1);
    const [totalPartPages, setTotalPartPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')
    const itemsPerPage = 7;
    const rowsPerPage = 10;
    const [editState, setEditState] = useState({
        productId: null,
        date: null,
        field: null,
        value: null,
    });
    const nav = useNavigate();
    const location = useLocation();
    const handleEdit = (part, date, field) => {
        setEditState({
            productId: part.id,
            date,
            field,
            value: part[field] || 0,
        });
    };



    const handleRowChange = (e) => {
        setEditState((prevState) => ({
            ...prevState,
            value: e.target.value,
        }));
    };

    const handleSave = async () => {




        try {
            const headers = {
                'x-auth-token': Cookies.get("token")
            };
            const payload = {
                id: editState.productId,
                new_value: editState.value,

            };
            console.log(payload);
            const response = await axios.post(
                `${BASE_URL}/v1/prod-plan/update-material-schedule`,
                payload,
                { headers }
            );
            // console.log(response)


            window.location.reload()
        } catch (error) {
            console.error("Error saving MBOM:", error);
        }


    };



    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setEditState({
            productId: null,
            date: null,
            field: null,
            value: null,
        });
    };



    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = params.get('page') || 1;
        const filter = params.get('filter') || 'daily';
        const start = params.get('start') || startDate;
        const end = params.get('end') || endDate;
        const search = params.get('search') || searchTerm;

        setCurrentPage(Number(page));
        setSelectedFilter(filter);
        setStartDate(start);
        setEndDate(end);
        setSearchTerm(search)



    }, []);

    // Update URL params when state changes
    useEffect(() => {
        fetchWorkPlanDebounced();
        updateUrlParams();
    }, [currentPage, selectedFilter, startDate, endDate, searchTerm]);

    // const updateUrlParams = debounce(() => {
    //     const params = new URLSearchParams();
    //     params.append('page', currentPage);
    //     params.append('filter', selectedFilter);
    //     params.append('start', startDate);
    //     params.append('end', endDate);
    //     params.append('search', searchTerm);
    //     nav({ search: params.toString() });
    // }, 300);
    const updateUrlParams = useCallback(debounce(() => {
        const params = new URLSearchParams();
        params.append('page', currentPage);
        params.append('filter', selectedFilter);
        params.append('start', startDate);
        params.append('end', endDate);
        params.append('search', searchTerm)
        nav({ search: params.toString() });
    }, 300), [currentPage, selectedFilter, startDate, endDate, searchTerm]);
    // Optionally, persist state in localStorage
    useEffect(() => {
        const appState = {
            currentPage,
            selectedFilter,
            startDate,
            endDate, searchTerm
        };
        localStorage.setItem('appState', JSON.stringify(appState));
    }, [currentPage, selectedFilter, startDate, endDate, searchTerm]);





    const fetchWorkPlan = async () => {
        try {
            setLoading(true);
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const savedState = JSON.parse(localStorage.getItem('appState')) || {};
            const page = savedState.currentPage || 1;
            const filter = savedState.selectedFilter || 'daily';
            const start = savedState.startDate || '';
            const end = savedState.endDate || '';
            const searching = savedState.searchTerm || '';
            const response = await axios.get(
                `${BASE_URL}/v1/prod-plan/get-material-plan-schedule`,
                {
                    params: {
                        part_limit: rowsPerPage,
                        part_page: currentPartPage,
                        date_page: page,
                        date_limit: itemsPerPage,
                        start_date: start,
                        end_date: end,
                        view_type: filter,
                        search_query: searching
                    },
                    headers: headers,
                }
            );

            const schedule = response.data.data.schedule || [];
            const inventory = response.data.data.inventory_details || [];
            setTotalPages(response.data.data.pagination.total_date_pages);
            setTotalPartPages(response.data.data.pagination.total_part_pages)
            setVisibleDates(schedule.map(item => item.date)); // Extracting dates for headers
            const inventoryMap = inventory.reduce((acc, item) => {
                acc[item.part_number] = item.quantity;
                return acc;
            }, {});

            // Add quantity to schedule data
            const updatedSchedule = schedule.map(day => ({
                ...day,
                parts: day.parts.map(part => ({
                    ...part,
                    quantity: inventoryMap[part.part_number] || '' // Add inventory quantity or 'NA'
                }))
            }));

            setScheduleData(updatedSchedule);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchWorkPlanDebounced = useCallback(debounce(fetchWorkPlan, 300), []);
    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
        setCurrentPage(1);
        updateUrlParams();  // Push updated filter to URL
    };

    const handlePagination = (direction) => {
        if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            // alert(currentPage+1)
            updateUrlParams();
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            // alert(currentPage-1)
            updateUrlParams();
        }
    };
    const handleChange = (e, type) => {
        if (type === 'start') {
            setStartDate(e.target.value);
        } else if (type === 'end') {
            setEndDate(e.target.value);
        }
        updateUrlParams();
    }
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        updateUrlParams();  // Push updated search term to URL
    };

    return (
        <div className='prod-sche-page'>
            <div className='prod-sche-top-nav'>
                <div className='prod-schedule-back-nav'>

                    <span>Material planning</span>
                </div>

                <div className='prod-schedule-filters'>
                    <div className="ticket-assign-page-filters-search" style={{padding:'8px',height:'35px'}}>
                        <SearchIcon style={{ color: "#001325" }} />
                        <input
                            type="text"
                            placeholder={`Search by part number,title`}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                    {/* <SearchFilter handleSearchChange={handleSearchChange} searchTerm={searchTerm} listType='part number,title' /> */}
                    <div className='production-date-filters'>
                        <span>Start date</span>
                        <input type='date' value={startDate} max={endDate} onChange={(e) => handleChange(e, 'start')} />
                        <span>-</span>
                        <span>End date</span>
                        <input type='date' value={endDate} min={startDate} onChange={(e) => handleChange(e, 'end')} />

                    </div>
                    <div className='prod-schedule-filters-btns'>
                        <button
                            className={`prod-schedule-filters-btn1 ${selectedFilter === 'daily' ? 'selected' : ''}`}
                            onClick={() => handleFilterClick('daily')}
                        >
                            Daily
                        </button>
                        <button
                            className={`prod-schedule-filters-btn2 ${selectedFilter === 'weekly' ? 'selected' : ''}`}
                            onClick={() => handleFilterClick('weekly')}
                        >
                            Week
                        </button>
                        <button
                            className={`prod-schedule-filters-btn3 ${selectedFilter === 'monthly' ? 'selected' : ''}`}
                            onClick={() => handleFilterClick('monthly')}
                        >
                            Month
                        </button>
                    </div>
                    <div className='prod-schedule-filters-btns'>
                        <button onClick={() => handlePagination('prev')}>&lt;</button>
                        <span style={{ width: '150px' }}> {currentPage} / {totalPages} </span>
                        <button onClick={() => handlePagination('next')}>&gt;</button>
                    </div>
                </div>
            </div>

            <div className='production-schedule-table'>
                {loading ? <Loading /> : (
                    <table className="production-schedule-table-view">
                        <thead className='production-schedule-head'>
                            <tr style={{ background: 'rgba(246, 246, 246, 0.50)' }}>
                                <th className='production-table-row-data' style={{ fontSize: '14px' }}>Part Number</th>
                                <th></th>
                                {visibleDates.map((date, index) => (
                                    <th key={index} className='production-table-row-data' style={{ fontSize: '14px', width: '500px' }}>{date}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {scheduleData[0]?.parts.map((part, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td rowSpan="3" className='production-table-row-head' style={{
                                            border: '1px solid #edf2f7', padding: '16px', fontSize: '16px'
                                        }}>
                                            <div className="part-name" style={{ textAlign: 'left' }}>
                                                {part.part_number}
                                                <br />
                                                <span>
                                                    {part.part_title && part.part_title.length > 15
                                                        ? `${part.part_title.slice(0, 15)}...`
                                                        : part.part_title}
                                                </span>
                                                <span style={{ display: 'block', fontSize: '14px', color: 'red', textAlign: 'left' }}>{part.quantity || ''}</span>
                                            </div>
                                        </td>
                                        <td style={{ border: '1px solid #edf2f7', padding: '16px', fontSize: '16px' }} className='production-table-row-head'>Onsite Inventory</td>

                                        {visibleDates.map((date, dateIndex) => (
                                            <td style={{
                                                border: '1px solid #edf2f7', padding: '16px', fontSize: '16px', wordBreak: 'break-all',
                                                background: scheduleData[dateIndex]?.parts[index]?.onsite_inventory === 'NA' ? 'rgba(246, 246, 246, 0.50)' : 'white',
                                            }} key={`onsite-${dateIndex}`} className='production-table-row-data'>
                                                {scheduleData[dateIndex]?.parts[index]?.onsite_inventory}

                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #edf2f7', padding: '16px', fontSize: '16px' }}>Incoming Shipments</td>

                                        {visibleDates.map((date, dateIndex) => {
                                            const part = scheduleData[dateIndex]?.parts[index];
                                            const today = new Date().toISOString().split('T')[0]; // Adjust date format if needed

                                            const isEditableDate = date >= today;

                                            const isEditing = 
                                                editState.productId === part?.id 
                                               

                                            return (
                                                <td
                                                    style={{
                                                        border: '1px solid #edf2f7',
                                                        padding: '16px',
                                                        wordBreak: 'break-all',
                                                        fontSize: '16px',
                                                        background: part?.incoming_shipments === 'NA' ? 'rgba(246, 246, 246, 0.50)' : 'white',
                                                    }}
                                                    key={`incoming-${dateIndex}`}
                                                    className='production-table-row-data'
                                                // onClick={() => {
                                                //     if (part?.incoming_shipments !== 'NA' && isEditableDate) {
                                                //         handleEdit(part, date, 'incoming_shipments');
                                                //     }
                                                // }}
                                                >
                                                    {part?.id && editState.productId === part?.id  ? (
                                                        <div className='edit-prod-table-cell'>
                                                            <input
                                                                type='number'
                                                                value={editState.value}
                                                                onChange={handleRowChange}
                                                            />
                                                            <div>
                                                                <img
                                                                    onClick={handleSave}
                                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                                    alt=""
                                                                    width="24px"
                                                                    height="24px"
                                                                    style={{ cursor: 'pointer' }}
                                                                />&nbsp;
                                                                <img
                                                                    onClick={(e) => handleCancel(e)}
                                                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                                                                    alt=""
                                                                    width="24px"
                                                                    height="24px"
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ textDecoration: part.grn_received_qty > 0 ? 'line-through' : 'none', display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                            {part ? (part.incoming_shipments !== 'NA' ? part.incoming_shipments : 'N/A') : 'N/A'}
                                                            {part.is_incoming_shipments_editable && <img
                                                                onClick={() => handleEdit(part, date, 'incoming_shipments')}
                                                                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                                alt=""
                                                                width="12px"
                                                                height="12px"
                                                                style={{ cursor: 'pointer' }}
                                                            />}
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                    <tr>
                                        <td style={{ border: '1px solid #edf2f7', padding: '16px', fontSize: '16px' }}>Usage</td>
                                        {visibleDates.map((date, dateIndex) => (
                                            <td style={{
                                                border: '1px solid #edf2f7', background: scheduleData[dateIndex]?.parts[index]?.usage === 'NA' ? 'rgba(246, 246, 246, 0.50)' : 'white', padding: '16px',
                                                fontSize: '16px', wordBreak: 'break-all'
                                            }} key={`usage-${dateIndex}`} className='production-table-row-data'>
                                                {scheduleData[dateIndex]?.parts[index]?.usage }

                                            </td>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>

                    </table>
                )}
            </div>
            {totalPartPages > 1 && <Pagination currentPage={currentPartPage} setCurrentPage={setCurrentPartPage} totalPages={totalPartPages} />}
        </div>
    );
};

export default MaterialPlanning;
