import React, { useState, useRef } from 'react'
import './Notification.css'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import Select from "react-select";
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ASSET_PREFIX_URL, BASE_URL, TICKET_ATTACHMENT_BUCKET } from "../../constants/config";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Loading from './../Common/Loading/Loading';
import AttachmentsFunction from './../Common/CommonJsx/AttachmentsFunction';
import EcPartsForm from '../EcPages/EcPartsForm';
import { makeByCodeOptions } from '../../constants/helper';
// create-count-minus.png      create-count-plus.png
function EditPartNumber({ handleClose, 
    getPartNumberList,  id }) {

    const [counterValues, setCounterValues] = useState([]);
    const [rightSectionItems, setRightSectionItems] = useState([]);
    const [addButton, setAddButton] = useState(Array(rightSectionItems.length).fill(true));
    const [minusButton, setMinusButton] = useState(Array(rightSectionItems.length).fill(false));

    const [partTitle, setpartTitle] = useState('');
    const [partDesc, setpartDesc] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const LIMIT = 6;
    const [selectedCode, setSelectedCode] = useState('');
    

    const [selectedPartNumbers, setSelectedPartNumbers] = useState([]);
    const [search, setSearch] = useState('');
    const [partNumberOptions, setPartNumberOptions] = useState([]);
    const [openSecondStep, setOpenSecondStep] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [fileSizeError, setFileSizeError] = useState('');
    const [action, setAction] = useState('');
    const [errors, setErrors] = useState('')
  
    const [loading, setLoading] = useState(false)
    const [attributeOptions, setAttributeOptions] = useState([]);
    const nav = useNavigate()




    // const handleQuantity = (e) => {
    //     setQuantity(e.target.value)
    // };

    const getPartNumberChildList = async () => {
        try {
            //   setLoading(true)
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const listofPartNumber = await axios.get(
                `${BASE_URL}/v1/part-nomen/get-list-part-number`,
                {
                    params: {
                        page: activePage,
                        limit: LIMIT,
                        // part_action: partAction,
                        search: search, // Include partAction if specified
                    },
                    headers: headers,
                }
            );
            setPartNumberOptions(search !== '' ? listofPartNumber.data.data.part_number_list : (prevNotifications) => [...prevNotifications, ...listofPartNumber.data.data.part_number_list]);
            //   console.log(listofPartNumber.data.data.part_number_list);
        
            //   setPartNumbers(listofPartNumber.data.data.part_number_list)

            //   setTotalPages(listofPartNumber.data.data.total_pages);
            setActivePage(search === '' && activePage + 1);
            //   setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };


   
   
    
   
    useEffect(() => {
        if (search !== '') {
            const delayTimer = setTimeout(() => {
                getPartNumberChildList();
            }, 1000); // Adjust the debounce delay (in milliseconds) as needed

            return () => clearTimeout(delayTimer);
        }
        else {
            getPartNumberChildList();
        }
        // Clear the timer on each input change

    }, [search]);
    const handlePartNumberClick = (selectedPartNumber, e) => {
        e.stopPropagation()

        const isSelected = selectedPartNumbers.includes(selectedPartNumber);

        setSelectedPartNumbers((prevSelected) =>
            isSelected
                ? prevSelected.filter((partNumber) => partNumber !== selectedPartNumber)
                : [...prevSelected, selectedPartNumber]
        );
    };
    const handleCounterIncrease = (value, meaning, position, action) => {

        const lastCharacter = value.slice(-1);
        const isAlphabetic = isNaN(parseInt(lastCharacter));

        const newValue = isAlphabetic
            ? value.slice(0, -1) + String.fromCharCode(lastCharacter.charCodeAt(0) + 1)
            : value.slice(0, -1) + (parseInt(lastCharacter, 10) + 1);

        setCounterValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[position] = newValue;
            return updatedValues;
        });

        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[position].default_value = newValue;

        setRightSectionItems(updatedRightSectionItems);

        // Update the specific button state
        setAddButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = false;
            return updatedButtons;
        });
        setMinusButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = true;
            return updatedButtons;
        });
    };

    const handleDecrement = (value, meaning, position, action) => {
        const lastCharacter = value.slice(-1);
        const isAlphabetic = isNaN(parseInt(lastCharacter));

        let newValue;
        if (isAlphabetic) {
            // Decrement the alphabetic character
            newValue = value.slice(0, -1) + String.fromCharCode(lastCharacter.charCodeAt(0) - 1);
        } else {
            const numericValue = parseInt(lastCharacter, 10);
            // Ensure the value is greater than 0 before decrementing
            if (numericValue > 0) {
                newValue = value.slice(0, -1) + (numericValue - 1);
            } else {
                // Optionally handle if the value is already at 0
                newValue = value;
            }
        }

        setCounterValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[position] = newValue;
            return updatedValues;
        });

        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[position].default_value = newValue;
        setRightSectionItems(updatedRightSectionItems);

        // Update the specific button state
        setAddButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = true;
            return updatedButtons;
        });
        setMinusButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = false;
            return updatedButtons;
        });
    };

    useEffect(() => {
        partNumberDetails();
    }, [id]);

    const partNumberDetails = async () => {

        try {
            setLoading(true) 
            //   setLoading(true)

            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const partNumberDetails = await axios.get(
                `${BASE_URL}/v1/part-nomen/get-part-details/${id}`,
                { headers }
            );
            console.log(partNumberDetails)
            console.log(partNumberDetails.data.data.part_details.make_buy_code)
            setAttributeOptions(partNumberDetails.data.data.part_details.attribute_data);
            setRightSectionItems(partNumberDetails.data.data.part_details.data)
            setpartDesc(partNumberDetails.data.data.part_details.description)
            setpartTitle(partNumberDetails.data.data.part_details.part_title)
            setSelectedCode(partNumberDetails.data.data.part_details.make_buy_code)
            setUploadedFiles(partNumberDetails.data.data.part_details.attachments)
            setAction(partNumberDetails.data.data.part_details.part_action)
            // setQuantity(partNumberDetails.data.data.part_details.child_parts.length)
            setSelectedPartNumbers(partNumberDetails.data.data.part_details.child_parts)
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };


    const handleCodeChange = (selected)=>{
        setSelectedCode(selected.value)
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "340px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7", // Remove the border
            boxShadow: "none", // Remove any box shadow
            //  border: 1px solid  #EDF2F7;
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none", // Hide the line (border)
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white", // Set the background color
            color: state.isSelected ? "black" : "inherit", // Set the text color
            cursor: "pointer", // Add cursor pointer on hover
            ":hover": {
                background: "lightgray", // Change the background color on hover
            },
        }),

        // You can define styles for other parts of the Select component here
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        handleFile(file);
    };
    const upload = () => {
        document.getElementById("fileupld").click();
    };
    const handleFileDrop = async (event) => {
        event.preventDefault();
        // setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];

            handleFile(file);
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };

    const handleFile = async (file) => {
        setFileSizeError('');
        setUploadError('');
        const fileSizeMB = file.size / (1024 * 1024);  // size in MB
        if (fileSizeMB > 2) {
            setFileSizeError('File size cannot be more than 2 MB');  // Notify the user
            return;  // Exit the function
        }
        //fetch pre signed URL
        setIsLoading(true);
        try {
            const headers = {
                'x-auth-token': Cookies.get('token')
            };
            const preSignedURL = await axios.post(`${BASE_URL}/v1/member/get-presigned-url`, { bucket_name: TICKET_ATTACHMENT_BUCKET, file: file.name }, { headers: headers })
            if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
                //upload to s3
                await axios.put(preSignedURL.data.data.url, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                });
                // file.name = preSignedURL.data.data.file_name;
                // handleFieldChange('file', file.name);
                // setFileFormat(getFileFormat(file.name));
                setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, { name: file.name, key: preSignedURL.data.data.key }]);
            } else {
                alert('Error generating signed URL')
            }
        } catch (e) {

            setUploadError('Error in uploading file');
        } finally {
            // Set isLoading back to false after upload (whether success or failure)
            setIsLoading(false);
        }
    }



    const handleSelectChange = (selected, meaning, position) => {
        const updatedRightSectionItems = [...rightSectionItems];

        updatedRightSectionItems[position].default_value = selected.value;



        setRightSectionItems(updatedRightSectionItems);

        // setCreatedData(prevData => ({
        //     ...prevData,
        //     [meaning]: selected.value,
        //   }));
    };

    const handlemeaningChange = (index, value, meaning) => {
        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[index].default_value = value;
        setRightSectionItems(updatedRightSectionItems);
    };




    const generateDynamicPartName = () => {
        const dynamicPart = rightSectionItems.map((item, index) => {
            if (
                item.variable_selected_type === "Fixed"

            ) {
                return item.default_value
            } else if (item.character_type === 'special_character') {
                return item.character_length
            } else if (item.variable_selected_type === "Counter" && item.is_unique === false) {
                return item.default_value

            } else if (item.variable_selected_type === "Counter" && item.is_unique === true) {
                return item.default_value

            } else if (item.character_type === "input_variable") {
                return item.default_value
            }
            return "";
        });

        return dynamicPart.join("").toUpperCase();
    };

    const dynamicPartNumber = generateDynamicPartName();
    const [selectedValues, setSelectedValues] = useState({});

    const handlemeasureChange = (selected, title) => {
        console.log(selected)
        setSelectedValues(prevState => ({
            ...prevState,
            [title]: selected.value // Set the selected value for the attribute title
        }));
    };

    const handlePartTitle = (e) => {
        setpartTitle(e.target.value)
    };
    const handlePartDesc = (e) => {
        setpartDesc(e.target.value)
    };

    const handleOpenSecondStep = () => {
       
            setOpenSecondStep(!openSecondStep)
       

    }

    const handlePrevStepToParts = () => {
        setOpenSecondStep(false)
    }

    const handleCreate = async (selectedPartNumbers) => {
        console.log(selectedPartNumbers)
        const headers = {
            'x-auth-token': Cookies.get('token')
        };
        const saveData = JSON.stringify(rightSectionItems);
        const part_name_nomen_id = rightSectionItems.map((item) => item.part_name_nomen_id
        )
        const is_variation = rightSectionItems.map((item) => item.is_variation
        )
        const attributes = JSON.stringify(attributeOptions.map(item => ({
            title: item.title,
            options: item.options.map(option => option),
            value: selectedValues[item.title] ? selectedValues[item.title] : item.value
        })))
        // console.log(attributes)
        // console.log(saveData)
        // if (!attributeOptions || !partTitle) {
        //     setErrors('Please fill all details');

        // } else {
            try {
                const response = await axios.post(
                    `${BASE_URL}/v1/part-nomen/create-new-part`,
                    {



                        data: saveData,
                        part_number: dynamicPartNumber,
                        make_buy_code:selectedCode,
                        part_title: partTitle,
                        description: partDesc,

                        attachments: uploadedFiles,
                        child_parts: selectedPartNumbers,
                        // quantity: selectedPartNumbers.length,
                        part_action: 'variation',
                        part_name_nomen_id: part_name_nomen_id[0],
                        is_variation: is_variation[0],
                        attribute_data: attributes,
                    },
                    { headers }
                );

                if (response.data.meta.success === false) {
                    console.error(response.data.meta.message);
                    setErrors(response.data.meta.message);
                } else {
                    setErrors('');
                    nav(`/part-view/${response.data.data.new_part}`)
                    // getPartNumberList();
                    handleClose();
                    getPartNumberList()

                }


            } catch (error) {
                console.error("An error occurred during the request:", error);

            }
        // }
    };

    return (
        <>
            {loading ? <Loading /> :
                <div className='create-part-number-popup-page'>
                    <div className='create-part-num-div' >
                        <div className='create-part-num-div-top'>
                            <span>Create Variation</span>
                            <PopupCloseButton handleClose={handleClose} />
                        </div>
                        <div className="dept-ticket-steps" style={{paddingLeft: '24px',paddingRight:'24px'}}>
                        <span>step 1 of 2</span>
                    </div>
                    <img
                        src={`${ASSET_PREFIX_URL}ticket-step1.svg`}
                        alt=""
                        width="95%"style={{paddingLeft: '24px',paddingRight:'24px'}}
                    />
                        <div className='create-part-num-div-form'>
                            <div className="create-part-num-errors">
                                <div className="create-part-num-div-part-number">
                                    <span>Part Number Preview</span>
                                    <span className="create-part-title">
                                        {generateDynamicPartName()}
                                    </span>

                                </div>
                                {errors !== '' && <div className="errors-msg-div">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                                    <span className="error-message">{errors}</span>
                                </div>}

                            </div>
                            <div className='create-part-num-div-form-dynamic'>
                                {rightSectionItems.map((item, index) => (
                                    <div key={index} >

                                        {item.variable_selected_type === 'Counter' && (
                                            <div className='create-part-num-div-form-label-input'>
                                                <span>{item.meaning}*</span>
                                                <div className='create-part-num-div-form-input'>
                                                    <input
                                                        type='text'
                                                        //   value={
                                                        //       item.is_unique
                                                        //           ? counterValues[item.position] || item.default_value
                                                        //           : counterValues[item.position] !== undefined
                                                        //               ? counterValues[item.position]
                                                        //               : item.default_value
                                                        //   }
                                                        value={
                                                            item.default_value
                                                        }
                                                        className='create-part-num-div-counter-input'
                                                        disabled={item.is_unique === true ? true : false}
                                                    />

                                                    {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Plus Icon'  /> */}
                                                    {item.is_unique === false ? <>
                                                        {(minusButton[index] === true && counterValues[index]) && <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Plus Icon'
                                                            onClick={() => handleDecrement(item.default_value, item.meaning, index, 'minus')} />}
                                                        {addButton[index] === false && counterValues[index] ? (
                                                            <img src={`${ASSET_PREFIX_URL}create-count-plus-disable.png`} alt='Plus Icon' />
                                                        ) : (
                                                            <img
                                                                src={`${ASSET_PREFIX_URL}create-count-plus.png`}
                                                                alt='Plus Icon'
                                                                onClick={() => handleCounterIncrease(item.default_value, item.meaning, index, 'add')}
                                                            />
                                                        )}
                                                    </> : ''}

                                                </div>
                                            </div>
                                        )}
                                        {item.variable_selected_type === 'Fixed' && (
                                            <div className='create-part-num-div-form-label-input'>
                                                <span>{item.meaning}*</span>
                                                <div className='create-part-num-div-form-input'>
                                                    <Select
                                                    isDisabled
                                                        styles={customStyles}
                                                        // value={{ value: item.partValue, label: item.partValue }}
                                                        value={{
                                                            value: item.default_value,
                                                            label: item.default_value
                                                        }}
                                                        options={item.variable_meaning.map((item) => ({
                                                            value: item.variable,
                                                            label: item.variable,
                                                        }))}
                                                        onChange={(selected) =>
                                                            handleSelectChange(selected, item.meaning, item.position)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {item.character_type === 'input_variable' && (
                                            <div className='create-part-num-div-form-label-input'>
                                                <span>{item.meaning}*</span>
                                                <div className='create-part-num-div-form-input'>
                                                    <input type='text'
                                                        value={item.partValue ? item.partValue : item.default_value}
                                                        // value={item.default_value}
                                                        onChange={(e) =>
                                                            handlemeaningChange(
                                                                index,
                                                                e.target.value,
                                                                item.meaning

                                                            )
                                                        } />

                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className='create-part-num-div-form-dynamic'>

                                <div className='create-part-num-div-form-label-input'>
                                    <span>Part title*</span>
                                    <div className='create-part-num-div-form-input'>
                                        <input type='text' placeholder='Part title'
                                            value={partTitle} onChange={(e) => handlePartTitle(e)} />
                                    </div>
                                </div>
                                <div className='create-part-num-div-form-label-input'>
                                    <span>Description</span>
                                    <div className='create-part-num-div-form-input'>
                                        <input type='text' placeholder='Describe this part (optional)'
                                            value={partDesc} onChange={(e) => handlePartDesc(e)} />
                                    </div>
                                </div>
                                <div className="create-part-num-div-form-label-input">
                                    <span>Make/buy code*</span>
                                  
                                        <Select styles={customStyles} value={{value: selectedCode, label: selectedCode}}  options={makeByCodeOptions} onChange={(selected)=>handleCodeChange(selected)}/>
                                        
                                </div>
                                {attributeOptions.map((item, index) => (
                                    <div className="create-part-num-div-form-label-input" key={index}>
                                        <span>{item.title}*</span>
                                        {/* <div className="create-part-num-div-form-input"> */}
                                            {/* Render your Select component here */}
                                            <Select
                                                styles={customStyles}
                                                options={item.options.map(option => ({
                                                    value: option,
                                                    label: option,
                                                }))}
                                                placeholder={`Select ${item.title}`}
                                                value={selectedValues[item.title] ? { value: selectedValues[item.title], label: selectedValues[item.title] } :
                                                    { value: item.value, label: item.value }}
                                                onChange={(selected) => handlemeasureChange(selected, item.title)}
                                            />

                                        {/* </div> */}
                                    </div>
                                ))}

                                {/* <div className="create-part-num-div-form-label-input">
                                    <span>Quantity*</span>
                                    <div className="create-part-num-div-form-input">
                                        <input
                                            type="text"
                                            placeholder="Enter Quantity"
                                            value={selectedPartNumbers.length}
                                        // onChange={(e) => handleQuantity(e)}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="create-part-num-div-form-label-input">

                                    <span>Choose child parts <span className='selected-parts-text'>({selectedPartNumbers.length}selected)</span></span>

                                    <div className="create-part-num-div-form-input-options">
                                        <div className='create-part-num-div-form-input-options-drop' onClick={(e) => handleOpenOptions(e)}>
                                            <input title={selectedPartNumbers.join(', ')}
                                                type="text" placeholder="Search child parts" value={search} onChange={(e) => handleSearch(e)}


                                            />
                                            <div className='droping-search-div'>
                                                <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" onClick={(e) => handleOpenOptions(e)} />
                                            </div>

                                        </div>


                                        {openOptions &&
                                            <InfiniteScroll
                                                dataLength={partNumberOptions.length}
                                                next={() => handleNextFunction()}
                                                hasMore={partNumberOptions.length > optionsCount}
                                                loader={
                                                    <div className="loading-indicator" style={{ color: "black" }}>
                                                        <h4>Loading...</h4>
                                                    </div>
                                                }
                                                className="infinite-scroll-content"
                                            >
                                                <div className='part-options-child-div' ref={containerRef} onScroll={handleScroll}>

                                                    {[...new Set(partNumberOptions.map(num => num.part_number))].map((partNumber, index) => (
                                                        <div
                                                            key={index}
                                                            className={`part-options-child ${selectedPartNumbers.includes(partNumber) ? 'selected' : ''}`}
                                                            onClick={(e) => handlePartNumberClick(partNumber, e)}
                                                        >
                                                            <span>{partNumber}</span>
                                                            <img
                                                                src={selectedPartNumbers.includes(partNumber) ? `${ASSET_PREFIX_URL}part-selected.png` : `${ASSET_PREFIX_URL}part-unselect.png`}
                                                                alt="Part"
                                                            />
                                                        </div>
                                                    ))}

                                                </div></InfiniteScroll>}

                                    </div>

                                </div> */}

                            </div>

                            <div className="create-part-num-div-form-label-attahment">
                                <span>Attachments*</span>
                                <div
                                    onDrop={handleFileDrop}
                                    onDragOver={handleDragOver}
                                    className="filedropzone"
                                >
                                    <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                    {isLoading ? <p>Uploading ....</p> :
                                        <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}

                                </div>
                                <input
                                    type="file"
                                    id="fileupld"
                                    style={{ display: "none" }}
                                    onChange={handleFileUpload}
                                    className="btn-upload"
                                    multiple
                                />
                            </div>
                            {!uploadError && <div className="error">{uploadError}</div>}
                            {fileSizeError && <div className="error"><span className="error-message"
                                style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {fileSizeError}</span></div>}
                            <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' />
                        </div>

                        <div className='create-part-num-div-btns'>
                            <button onClick={handleOpenSecondStep}>Next</button>
                            <button onClick={handleClose}>Cancel</button>
                        </div>
                    </div>
                </div>}
            {openSecondStep && <EcPartsForm typeEC='part-variation'
                handlePrevStepToParts={handlePrevStepToParts} onClose={handleClose} handleCreate={handleCreate} selectedPartNumbers={selectedPartNumbers}
                 errorMessage={errors}/>}
        </>

    )
}

export default EditPartNumber