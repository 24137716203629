import React from 'react'
import './DetailsView.css'

function DeleteTicketPopUp({onClose}) {
  return (
    <div className='delete-ticket-page'>
        <div className='delete-ticket-cont'>
            <div className='change-details-title'>
                <span>Delete ticket?</span>
            </div>
            <div className='change-details-description'>
                <span>Are you sure you want to delete the ticket?</span>
            </div>
            <div className='change-details-buttons'>
                <button className='change-details-change'>Yes, delete</button>
                <button className='change-details-cancel' onClick={onClose}>Cancel</button>
            </div>
        </div>
    </div>
  )
}

export default DeleteTicketPopUp