import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './CreateGRN.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../constants/config';
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';
import { useNavigate } from 'react-router-dom';

const options = [
    { value: 'shortfall', label: 'Shortfall' },
    { value: 'rejection', label: 'Rejection' },
];

const CreateGRN = ({ handleClose, sequence_id }) => {
    const [defaultDataView, setDefaultDataView] = useState([]);
    const [inputValues, setInputValues] = useState({});
    // const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectValues, setSelectValues] = useState({});
    const [remarksValues, setRemarksValues] = useState({});
    const [changedRows, setChangedRows] = useState(new Set());
    const [errorMessage, setErrorMessage] = useState('');
    const nav = useNavigate();

    useEffect(() => {
        fetchPoDetails();
    }, []);

    const fetchPoDetails = async () => {
        try {
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
           
            const grnresponse = await axios.get(`${BASE_URL}/v1/goods-receipts/get-grn-popup-details`, {
                headers, params:
                    { po_number: sequence_id }
            });
            // setDefaultDataView(response.data.data.po_details.data.tableDetails);
            setDefaultDataView(grnresponse.data.data.details)
          
           
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const handleInputChange = (index, value) => {
        setInputValues({
            ...inputValues,
            [index]: parseInt(value, 10) || 0
        });
        setChangedRows(new Set(changedRows).add(index));
    };

    const handleSelectChange = (index, selectedOption) => {
        setSelectValues({
            ...selectValues,
            [index]: selectedOption.value
        });
        setChangedRows(new Set(changedRows).add(index));
    };

    const handleRemarksChange = (index, value) => {
        setRemarksValues({
            ...remarksValues,
            [index]: value
        });
        setChangedRows(new Set(changedRows).add(index));
    };

    const handleSave = async () => {
        // Validation: Check if received quantities exceed PO quantities, remaining quantities are negative, or received quantities are zero
        const invalidRows = defaultDataView.filter((row, index) => {
            const receivedQty = Number(inputValues[index]) ;
            const remainingQty = row['quantity'] - (Number(row['total_grn_received_qty']) ) - receivedQty;
            return receivedQty > row['quantity'] || remainingQty < 0 || receivedQty <= 0;
        });
    
        if (invalidRows.length > 0) {
            setErrorMessage('Received quantity should not be greater than PO quantity and must be greater than zero.');
            return;
        } else {
            setErrorMessage('');
        }
    
        try {
            setLoading(true);
            const tableDataToSave = defaultDataView.map((row, index) => ({
                part_number: row['part_number'],
                part_title: row['part_title'],
                purchase_order_qty: row['quantity'],
                received_qty: Number(inputValues[index]) || 0,
                remaining_qty: row['quantity'] - (Number(inputValues[index]) || 0),
                grn_status: options.find(opt => opt.value === (selectValues[index] || options[0].value)).label,
                remarks: remarksValues[index] || '',
            }));
    
            // Filter changed rows
            const changedRowsData = Array.from(changedRows).map(index => ({
                ...tableDataToSave[index]
            }));
            console.log("Changed Rows:", changedRowsData);
    
            const headers = {
                "x-auth-token": Cookies.get("token"),
            };
            const payload = {
                data: JSON.stringify(changedRowsData),
                purchase_order_number: sequence_id,
            };
            console.log(payload);
            const response = await axios.post(
                `${BASE_URL}/v1/goods-receipts/create-grn`,
                payload,
                { headers: headers }
            );
    
            nav(`/all-grn-parts/${sequence_id}`);
        } catch (error) {
            console.error("Error saving data:", error);
        }finally {
            setLoading(false);  // End loading
        }
    };
    


    
    return (
        <div className='create-grn-popup'>
            <div className="create-grn-div">
                <div className="modal-content">
                    <div className="modal-header">
                        <span>Create GRN</span>
                        <PopupCloseButton handleClose={handleClose} />
                    </div>
                    <div className="create-grn-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. no.</th>
                                    <th>Part number</th>
                                    <th>Description</th>
                                    {/* <th> PO qty</th> */}
                                    <th>Received qty</th>
                                    <th>Remaining qty</th>
                                    <th>Total received qty / PO qty</th>
                                    <th>Shortfall/ Rejection</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {defaultDataView.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row['part_number']}</td>
                                        <td>{row['part_title']}</td>
                                        {/* <td>{row['quantity']}</td> */}
                                        <td>
                                            <input
                                                type="number"
                                                disabled={row['quantity'] -  (Number(row['total_grn_received_qty']) || 0) -
                                                    (Number(inputValues[index]) || 0)===0}
                                                placeholder='Enter qty'
                                                className="create-grn-table-input"
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                            />
                                        </td>
                                        <td>{row['quantity'] -  (Number(row['total_grn_received_qty']) || 0) -
                                                (Number(inputValues[index]) || 0)}</td>
                                        {/* <td>{inputValues[index]||0}/{row['quantity']}</td> */}
                                        <td>
                                            {(
                                                (Number(row['total_grn_received_qty']) || 0) +
                                                (Number(inputValues[index]) || 0)
                                            )}/{Number(row['quantity']) || 'N/A'}
                                        </td>

                                        <td>
                                            <Select
                                                options={options}
                                                // defaultValue={options[0]}
                                                onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
                                                // isDisabled={(inputValues[index] || 0) === 0}
                                                isDisabled={defaultDataView[index]['quantity'] - (Number(row['total_grn_received_qty']) || 0) -
                                                    (Number(inputValues[index]) || 0) === 0}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className='create-grn-table-remarks'
                                                placeholder="Enter remarks"
                                                onChange={(e) => handleRemarksChange(index, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {errorMessage && (
                        <div className="error-message">
                            {errorMessage}
                        </div>
                    )}
                    <div className="modal-footer">
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <button className="btn go-back" onClick={handleClose}>Go back</button>
                            <button className="btn partial-receive" disabled={loading} onClick={handleSave}>Partial Receive</button>
                        </div>
                        {/* <button className="btn close-po" onClick={()=>handleClosePo(sequence_id)}>Close PO</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateGRN;
