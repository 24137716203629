import React, { useState } from 'react';
import PopupCloseButton from './../Common/PopupCloseButton/PopupCloseButton';
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";

function PartSupplierAttributes({ type, onclose, data, supplierId,partNumberRow,handleClearRow }) {
    console.log(partNumberRow)
    const [attributesData, setAttributesData] = useState(data);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAttributesData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Check if any attribute is zero or empty
    const isAddButton = !attributesData.lead_time || !attributesData.shipment_quantity;

    const handleSave = async () => {
        try {
            const headers = {
                'x-auth-token': Cookies.get("token")
            };
            const payload = {
                supplier_id: supplierId,
                part_supplier_data: JSON.stringify([attributesData])
            };
            console.log(payload);
            const response = await axios.post(
                `${BASE_URL}/v1/purchase-order/create-part-supplier-mapping`,
                payload,
                { headers }
            );
            onclose()
            console.log(response);
        } catch (error) {
            console.error("Error saving MBOM:", error);
        }
    };

    const handleClose = () => {
        if (!type) {
            handleClearRow(partNumberRow)
        }
        onclose(); // Call the provided onclose handler
    };
    return (
        <div className="create-part-number-popup-page">
            <div className='create-part-num-div' style={{ width: '30%' }}>
                <div className='create-part-num-div-top'>
                    <span>Update {data.part_number} {type}</span>
                    <PopupCloseButton handleClose={handleClose} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '24px', gap: '8px' }}>
                    {/* Render inputs conditionally based on the type */}
                    {(!type || type === 'Lead Time') && (
                        <>
                            <span>Lead time</span>
                            <input
                                type='number'
                                name='lead_time'
                                value={attributesData.lead_time || ''}
                                onChange={handleChange}
                                style={{ padding: '12px', borderRadius: '4px', border: '1px solid #edf2f7' }}
                            />
                        </>
                    )}
                    {(!type || type === 'Shipping Quantity') && (
                        <>
                            <span>Shipment quantity</span>
                            <input
                                type='number'
                                name='shipment_quantity'
                                value={attributesData.shipment_quantity || ''}
                                onChange={handleChange}
                                style={{ padding: '12px', borderRadius: '4px', border: '1px solid #edf2f7' }}
                            />
                        </>
                    )}
                </div>
                <div className="collab-members-btns" style={{ padding: '0px 24px' }}>
                    <div className="collab-btns" style={{ width: '100%', justifyContent: 'flex-end' }}>
                        {isAddButton?<button className='btn-sv1'>Save</button>:<button onClick={handleSave} className='btn-sv2'>Save</button>}
                       
                        {type &&  <button className="btn-cl" onClick={onclose}>
                            Cancel
                        </button>}
                       
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartSupplierAttributes;
