import React, { useState } from "react";
import {
  ASSET_PREFIX_URL,
  PHOTO_LINK,
  DEFAULT_COMPANY_LINK,
  DEFAULT_PHOTO,
} from "../../../constants/config";
// import "./TicketTemplate.css";
import "./Org.css";
import MemberSettings from "../MemberSettings/MemberSettings";
import Notification from "../../PopUpsFolder/Notification";
import NameProfile from "../CommonJsx/NameProfile";

function TicketTemplateTopNav({
  photo,
  company,
  memberPhoto,
  memberName,
  memberEmail,
  notificationCount, oncount
}) {
  const [memberSettingOpen, setMemberSettingOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  // const [allNotification, setAllNotifications] = useState([]);
  // const [notificationCount,setNotificationCount] = useState('')

  const handleOpenSettings = (e) => {
    e.stopPropagation();
    setMemberSettingOpen(!memberSettingOpen);
  };
  const handleCloseSettings = () => {
    setMemberSettingOpen(false);
  };

  //   const getNotification = async () => {
  //     try {
  //         const headers = {
  //             'x-auth-token': Cookies.get('token')
  //         };
  //         const response = await axios.get(`${BASE_URL}/v1/notification/all-notifications`, { headers: headers });
  //         console.log(response.data.data.all_notification)
  //         setAllNotifications(response.data.data.all_notification)
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }
  const handleOpenNotifications = () => {

    setNotification(true);
    // getNotification()
  };
  const handleCloseNotification = () => {
    setNotification(false);
  };


  // const logout = () => {
  //  Cookies.remove('token');
  //  localStorage.clear();
  //   nav("/login");
  //   // window.location.reload();
  // }
  const handleMakeZero = () => {
    oncount()
  }
  return (
    <>
      <div className="orgTopNav" onClick={handleCloseSettings}>
        <div className="org-logo">
          <a href="/org-home">
            {photo ? (
              <img src={`${PHOTO_LINK}${photo}`} alt="marathon" />
            ) : (
              <img src={DEFAULT_COMPANY_LINK} alt="marathon" />
            )}
          </a>
          <div className="companyName-title">
            <span>{company}</span>
            {/* <span>hardware project</span> */}
          </div>
        </div>

        <div className="icon-flex">
          {/* {isAdmin ? (
            <button className="btn-collab" onClick={handleCollabOpen}>
              Add collaborator
            </button>
          ) : (
            '' // Show an empty fragment if not an admin (i.e., nothing will be rendered)
          )} */}
          {/* <PowerSettingsNewOutlinedIcon
            
            style={{
              color: "#610BEE",
              cursor: "pointer",
              width: "40px",
              height: "40px",
            }}
            onClick={logout}
          /> */}
          <div className="notifi-counting" onClick={handleOpenNotifications}>
            <img src={`${ASSET_PREFIX_URL}bell_icon.png`} alt="notification" />
            {notificationCount > 0 ? (
              <div className="notif-count-num">
                {notificationCount < 6 ? notificationCount : "5+"}
              </div>
            ) : (
              ""
            )}
          </div>
          <div onClick={(e) => handleOpenSettings(e)} style={{cursor:'pointer'}}>
          <NameProfile userName={memberName} memberPhoto={memberPhoto} width= "50px" fontSize='24px' fontweight='500' />
          </div>
         


          {/* <div className="member-hover-details">
              <span>Marathon Account</span>
              <span>{memberEmail}</span>
              <span>{memberName}</span>
            </div> */}
        </div>
      </div>
      {memberSettingOpen && (
        <MemberSettings
          onClose={handleCloseSettings}
          memberPhoto={memberPhoto}
          memberEmail={memberEmail}
          memberName={memberName}
        />
      )}
      {notification && <Notification onclose={handleCloseNotification} handleMakeZero={handleMakeZero} />}
    </>
  );
}

export default TicketTemplateTopNav;
