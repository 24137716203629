import React, { useState } from 'react'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";

function ApprovePopUp({ onclose, sequence_id, type, ticketId,
    ticketName,
    notifyIds }) {
    const [approveMessage, setApproveMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
     

    const handleChange = (e) => {
        setErrorMessage('')
        setApproveMessage(e.target.value)
    }
    const handleApprove = async () => {
        if (!approveMessage) {
            setErrorMessage('Please enter reason to approve')
        } else {
            try {
                const headers = {
                    'x-auth-token': Cookies.get('token'),
                };
                if (type === 'trail') {
                   
                    await axios.post(`${BASE_URL}/v1/prod-change/make-trail-approve`, {
                        sequence_id,
                        pc_id: ticketId,
                    }, { headers: headers });
                  
                } else if (type === 'plan') {
                   
                    await axios.post(`${BASE_URL}/v1/prod-change/make-plan-approve`, {
                        sequence_id,
                        pc_id: ticketId,
                    }, { headers: headers });
                   
                }else if(type === 'pci-view'){
                    await axios.post(`${BASE_URL}/v1/manufac-bom/make-approve`, {
                        sequence_id,
                        product_id: ticketId,
                    }, { headers: headers });
                }else if(type === 'ec-template'){
                    await axios.post(`${BASE_URL}/v1/ec-template/make-approve`, {
                        sequence_id,
                        ec_id: ticketId,
                    }, { headers: headers });
                }else if(type === 'ec-deviation'){
                    await axios.post(`${BASE_URL}/v1/ec-deviations/make-approve`, {
                        sequence_id,
                        dev_id: ticketId,
                    }, { headers: headers });
                }



                await axios.post(
                    `${BASE_URL}/v1/comment/create-comment/${sequence_id}`,
                    {
                        comments: `approved: ${approveMessage}`, ticket_id: ticketId, ticket_title: ticketName,
                        member_ids: notifyIds
                    },
                    { headers }
                );


                window.location.reload()
                onclose()
            } catch (error) {
                console.log(error)
            }
        }



    }
    return (
        <div className='notification-page'>
            <div className='notification-div'>
                <div className='create-part-num-div-top'>

                    {type === 'pci-view' && <span>Approving PCI </span>}
                    {type === 'trail' && <span>Approving trial PC </span>}
                    {type === 'plan' && <span>Approving plan PC </span>}
                    {type === 'ec-template' && <span>Approving plan EC </span>}
                    {type === 'ec-deviation' && <span>Approving plan Deviation </span>}
                    <PopupCloseButton

                        handleClose={onclose}
                    />
                </div>
                <div className='reject-text-area'>
                    <div className='reject-mesage-error'>
                        <textarea placeholder='Please enter reason to approve*' value={approveMessage}
                            onChange={(e) => handleChange(e)} />
                        {errorMessage !== '' && <span className='error-message'>{errorMessage}</span>}
                    </div>


                    <button onClick={handleApprove}>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default ApprovePopUp