import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import { useNavigate } from "react-router-dom";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { useTour } from "@reactour/tour";
import Select from 'react-select'
import InventoryPopUps from "../PopUpsFolder/InventoryPopUps";
import Pagination from "../Common/CommonJsx/Pagination";
import { debounce } from "@mui/material";
import SearchFilter from "../Common/CommonJsx/SearchFilter";

function InventoryList() {
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [totalPages, setTotalPages] = useState(1);


  const [inventryData, setInventryData] = useState({});
  const [resetButton, setResetButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate()

  // Check local storage for tour completion status
  // const isTourCompleted = localStorage.getItem('tourCompleted');
  const { setIsOpen, setSteps, isOpen, setCurrentStep } = useTour();
  useEffect(() => {
    // Reset filters when type changes
    setSelectedStatus(null);
    setSearchTerm("");
  }, []);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleNextClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  // const handleStatusChange = (selectedOption) => {
  //   setSelectedStatus(selectedOption.value);
  //   setResetButton(true)
  // };
  const handleReset = async () => {

    // setDepartmentId('')

    setSelectedStatus(null)
    setSearchTerm('')
    // setPartAction('')
    setResetButton(false)


    // setReset(false)
  };
  const handlePreviousClick = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleDone = () => {
    setIsOpen(false);
    Cookies.set("ECTourCompleted", true, { expires: 365 });
  };

  const ticketSteps = [
    {
      selector: '[data-tour="step-8"]',
      content: (
        <div>
          <p>Create your new EC</p>
          {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
          <div className="tour-btns">
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="step-9"]',
      content: (
        <div>
          <p>Click to view EC</p>
          {/* <p>Click to view tickets assigned to you</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleDone()} className="tour-next">
              Done
            </button>
          </div>
        </div>
      ),
    },
  ];
  const emptyTicketSteps = [
    {
      selector: '[data-tour="step-8"]',
      content: (
        <div>
          <p>Create your new EC</p>

          {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleDone()} className="tour-next">
              Done
            </button>
          </div>
        </div>
      ),
    },
  ];

  const [hasOpened, setHasOpened] = useState(false);
  // const {member_id}=useParams();

  // const nav = useNavigate();
  const { id } = useParams();


  const handleTitleClick = (event, department) => {
    event.stopPropagation();
    //   setTicketPopUp(true);
    //   setTicketTitle(department.data.defaultFieldsData.name.value);
  };



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true)
  };

  const allTickets = async () => {

    try {
      setLoading(true);
      const headers = {
        "x-auth-token": Cookies.get("token"),
      };
      let response;

      response = await axios.get(
        `${BASE_URL}/v1/inventory/get-inventory`,

        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            search: searchTerm,

          },
          headers: headers,
        }
      );
      console.log(response)
      const details = response.data.data.inventory;
      console.log(response.data.data.inventory)
      const paginationDetails = response.data.data.pagination;
      console.log(details);
      setLength(details.length);

      setDepartmentList(details);
      setTotalPages(paginationDetails.total_pages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  useEffect(() => {
    if (searchTerm !== "" || selectedStatus) {
     debounce(allTickets)()
    } else {
      allTickets();
    }
  }, [currentPage, itemsPerPage, searchTerm]);



  const handleTicketView = (itemId) => {
    // ec-ticket-view

    //   let navigateTo;
    //   if (type === "ec-template") {
    //     navigateTo = "ec-ticket-view";
    //   } else {
    //     navigateTo = "deviation-ticket-view";
    //   }
    nav(`/inventory-log/${itemId}`);
    // window.location.pathname = `/ec-ticket-view/${itemId}`;
  };

  const handleHoverTour = () => {
    const tourCompleted = Cookies.get("ECTourCompleted");
    if (!tourCompleted) {
      setIsOpen(true);
      setSteps(length > 0 ? ticketSteps : emptyTicketSteps);

      setHasOpened(true);
    }
    if (tourCompleted === true) {
      setIsOpen(false);
    }

    // Check if the tour is closing after being opened
    if (!isOpen && hasOpened) {
      setIsOpen(false);

      Cookies.set("ECTourCompleted", true, { expires: 365 });
    }
  };

  const actionOptions = [
    { value: 'INVENTORY_MANUAL', label: 'Inventory out' },
    { value: 'MISSING', label: 'Missing quantity' },

  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [openInventory, setOpenInventory] = useState(null);

  const handleChange = (option, data,event) => {

    setSelectedOption(option.value);
    setOpenInventory(!openInventory)
    console.log('Selected option:', option);
    setInventryData(data)
    // Perform any action with the selected option
  };
  const handleCloseInventory = () => {
    setOpenInventory(!openInventory)
    setSelectedOption(null);
  }

  return (



    <>
      <div
        className="depart-main"
        style={{ marginTop: "80px" }}
        onMouseEnter={handleHoverTour}
      >
        <>
          {" "}
          <div className="spn-depart">
            {id ? (
              <>
                <div className="no-ticket-view-right">
                  {/* <ArrowBackIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
                        <span>Programs / </span> */}
                  <span style={{ color: "#610BEE" }}>EC</span>
                  <span> Ticket Progress</span>
                </div>
                {/* <div className='no-ticket-progress'>
                Ticket Progress
              </div> */}
              </>
            ) : (
              <>
                <span>
                  Inventory
                </span>
              </>
            )}


          </div>
        </>

        <div className="tck-fltr">

         
          <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='inventory...'/>

          {resetButton && <button
            className="reset-btn"
            onClick={handleReset}
            data-tour="step-11"
          >
            reset <TuneOutlinedIcon />
          </button>}
          {/* </>
            )} */}

          {/* {id ? '' : <> */}
        </div>
        {length < 1 ? (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="department-table">
                  <table className="department-table-data">
                    <thead>
                      <tr>
                        {/* <th className="id-header">Item id</th> */}
                        <th className="depart-header">Part number </th>
                        <th className="depart-header">Part title </th>
                        {/* <th className="lead-header">owner</th> */}
                        <th className="tck-header">Inventory Qty</th>
                        {/* <th className="tck-header">Created on</th> */}
                        {/* <th className="tck-header">Last used on</th> */}
                        {/* <th className="tck-header">Last used by</th> */}
                        <th className="tck-header">Actions</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <div className="department-table">
                <table className="department-table-data">
                  <thead>
                    <tr>
                      {/* <th className="id-header">Item id</th> */}
                      <th className="depart-header">Part number </th>
                      <th className="depart-header">Part title </th>
                      {/* <th className="lead-header">owner</th> */}
                      <th className="tck-header">Inventory Qty</th>
                      {/* <th className="tck-header">Created on</th> */}
                      {/* <th className="tck-header">Last used on</th>
                      <th className="tck-header">Last used by</th> */}
                      <th className="tck-header">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {departmentList.map((department, item) => (
                      <tr
                        key={item}
                        // onClick={() =>
                        //   handleTicketView(department.part_number)
                        // }
                        style={{ cursor: "pointer" }}
                        data-tour="step-9"
                      >
                        {/* <td onClick={() =>
                          handleTicketView(department.part_number)
                        }>{department._id}</td> */}

                        <td onClick={() =>
                          handleTicketView(department.part_number)
                        }>
                          {department.part_number}
                        </td>
                        <td onClick={() =>
                          handleTicketView(department.part_number)
                        }>
                          {department.part_details.part_title}
                        </td>

                        <td onClick={() =>
                          handleTicketView(department.part_number)
                        }>
                          <div
                            className="member-option"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {/* <NameProfile
                                userName={
                                  department.data.defaultFieldsData.assignee
                                    .value.fullName
                                }
                                memberPhoto={
                                  department.data.defaultFieldsData.assignee
                                    .value.photo
                                }
                                width="26px"
                                fontweight="500"
                              /> */}

                            <span className="member-fullname">
                              {
                                department.quantity
                              }
                            </span>
                          </div>
                        </td>


                        {/* <td onClick={() =>
                          handleTicketView(department.part_number)
                        }>
                          <div
                              className="member-option"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <span className="member-fullname">
                                {
                                  department.data.defaultFieldsData.supplier_gst_number
                                    .value
                                }
                              </span>
                            </div>
                         
                        </td> */}
                        {/* <td onClick={() =>
                          handleTicketView(department.part_number)
                        }>
                          <div
                              className="member-option"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <span className="member-fullname">
                                {
                                  department.data.defaultFieldsData.supplier_gst_number
                                    .value
                                }
                              </span>
                            </div>dhfg
                        </td> */}
                        <td>
                          <Select options={actionOptions} onChange={(option,event) => handleChange(option, department)} 
                            onFocus={(e)=>e.stopPropagation()}/>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}

        {/* {length < 1 ? (
            ""
          ) : (
            <> */}
        {totalPages > 1 && (
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        )}
        {/* {NewTicket && <DepartmentTicketPopup/>} */}
        {/* </>
          )} */}
      </div>
      {openInventory && <InventoryPopUps selectedOption={selectedOption} handleCloseInventory={handleCloseInventory} data={inventryData} />}
    </>


  )

}

export default InventoryList