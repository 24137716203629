import React from 'react'
import SearchIcon from "@mui/icons-material/Search";

function SearchFilter({handleSearchChange,searchTerm,listType}) {
  return (
    <div className="ticket-assign-page-filters-search">
    <SearchIcon style={{ color: "#001325" }} />
    <input
        type="text" 
        placeholder={`Search by ${listType}`} 
        value={searchTerm}
        onChange={handleSearchChange}
    />
</div>
  )
}

export default SearchFilter