import React from 'react'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function Pagination({currentPage,setCurrentPage,totalPages}) { 
console.log(currentPage,setCurrentPage)
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) { 
            setCurrentPage(newPage);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="pagination">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="prev-button"
            >
                <KeyboardBackspaceIcon />
                prev
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
                <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={`pagination-button ${currentPage === index + 1 ? "active" : ""
                        }`}
                >
                    {index + 1}
                </button>
            ))}
            <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="next-button"
            >
                next
                <KeyboardBackspaceIcon
                    style={{ transform: "rotate(180deg)" }}
                />
            </button>
        </div>
    )
}

export default Pagination